<template>
  <div class="multi-invoicing" v-if="canAccess">
    <page-header
      :title="'Facturation ' + pageLabel" icon="fa fa-receipt"
      :links="getLinks()"
    ></page-header>
    <div>
      <b-row class="row-line">
        <b-col v-if="!isNursery">
          <span v-for="schoolYear of schoolYears" :key="schoolYear.id" class="badge badge-dark badge-inline">
            {{ schoolYear.name }}
          </span>
          <youth-home-filter
            :block="false"
            @changed="onFilterChanged"
            v-if="showYouthFilter"
          ></youth-home-filter>
          <activities-select
            @changed="onActivitiesChanged"
            :include-youth="!!category"
            :category="category"
            v-if="showActivitiesFilter"
          >
          </activities-select>
        </b-col>
        <b-col :cols="isNursery ? 6 : 2">
          <b-form-checkbox
            id="dateToDate"
            v-model="dateToDate"
            name="dateToDate"
            :value="true"
            :unchecked-value="false"
          >
            <b v-if="isNursery">Facturation date à date</b>
            <b v-else>Date à date</b>
          </b-form-checkbox>
          <br />
          <div v-if="dateToDate" class="date-to-date-selector">
            <b-form-select v-model="dateChoice">
              <b-form-select-option :value="0">Période libre</b-form-select-option>
              <b-form-select-option :value="1">Mois écoulé</b-form-select-option>
              <b-form-select-option :value="2">Mois en cours</b-form-select-option>
              <b-form-select-option
                v-if="showYouthFilter" :value="3" :disabled="!(selectedSchoolYear && selectedPeriods.length > 0)"
              >
               Dates des périodes sélectionnées
              </b-form-select-option>
            </b-form-select>
            <br />
            <date-frame-selector
              :start-date="startDate"
              :end-date="endDate"
              @change="onDateRangeChanged"
              :disabled="!canChangeDate"
            >
            </date-frame-selector>
          </div>
        </b-col>
        <b-col v-if="isNursery" cols="5"></b-col>
        <b-col cols="1" class="text-right">
          <a class="btn btn-primary" href @click.prevent="loadInvoicing()" v-if="showView">
            <i class="fa fa-search"></i> Voir
          </a>
        </b-col>
      </b-row>
      <b-row class="row-line" v-if="showYouthFilter">
        <b-col>
          <b-form-group
            label-for="includeNotConfirmed"
            description="Inscriptions faites depuis portail famille et en attente de confirmation ou en attente"
          >
            <b-form-checkbox
              id="includeNotConfirmed"
              v-model="includeNotConfirmed"
              name="includeNotConfirmed"
              :value="true"
              :unchecked-value="false"
            >
              <b>Inclure les inscriptions en attente de confirmation ou sur liste d'attente</b>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col v-if="seanceFilters.length" cols="4">
          <b-form-select v-model="selectedSeanceFilter" id="seance-filter">
            <b-select-option v-for="elt of seanceFilters" :key="elt.id" :value="elt">
              {{ elt.name }}
            </b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="row-line" v-if="showActivitiesFilter">
        <b-col>
          <b-form-group
            label-for="includeTrying"
            description="Attention! une fois facturée, les inscriptions ne sont plus à l'essai"
          >
            <b-form-checkbox
              id="includeTrying"
              v-model="includeTrying"
              name="includeTrying"
              :value="true"
              :unchecked-value="false"
            >
              <b>Inclure les inscriptions à l'essai</b>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <div class="warning-text" v-if="includeTrying">
            Lorsqu'une inscription à l'essai est facturée,
            elle devient active et n'est donc plus à l'essai.
          </div>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
      <b-row class="row-line" v-if="showIncludeAdhesions && (showActivitiesFilter || showYouthFilter)">
        <b-col>
          <b-form-group
            label-for="includeAdhesions"
          >
            <b-form-checkbox
              id="includeAdhesions"
              v-model="includeAdhesions"
              name="includeAdhesions"
              :value="true"
              :unchecked-value="false"
            >
              <b>Inclure les adhésions</b>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-line" v-if="showYouthFilter && showIncludeYouthActivities">
        <b-col>
          <b-form-group
            label-for="includeYouthActivities"
          >
            <b-form-checkbox
              id="includeYouthActivities"
              v-model="includeYouthActivities"
              name="includeYouthActivities"
              :value="true"
              :unchecked-value="false"
            >
              <b>Inclure les inscriptions aux accueils de loisirs</b>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <loading-gif :loading-name="multiInvoicingLoading"></loading-gif>
      <tabs-bar
        enabled
        v-if="loaded"
        :tabs="tabs"
        :active="activeTab"
        @change="onTabChanged($event)"
      >
      </tabs-bar>
      <div ref="printMe" v-if="!isLoading(multiInvoicingLoading)">
        <div v-if="loaded" ref="excelTable">
          <b-row>
            <b-col v-if="activeTab.name === 'todo'">
              <div v-if="items.length === 0" class="no-items-message">
                <b v-if="appName === 'youth'">Pas de séances à facturer pour les périodes sélectionnées</b>
                <b v-if="appName === 'adhesions'">Pas d'adhésions à facturer</b>
                <b v-if="appName === 'activities'">Pas d'activités à facturer</b>
              </div>
              <div v-else>
                <div class="table-header">
                  <b-row>
                    <b-col>
                      <counter-label
                        :counter="items.length"
                        label="facture à créer"
                        labelN="factures à créer"
                      ></counter-label>
                    </b-col>
                    <b-col class="text-center">
                      <counter-label :counter="selectedItems.length" label="sélectionné">
                      </counter-label>
                    </b-col>
                    <b-col class="text-right">
                      <a
                        v-if="hasPerm('payments.add_invoice') && selectedItems.length"
                        href
                        @click.prevent="onCreate"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fa fa-arrow-alt-circle-right"></i> Facturer
                      </a>
                    </b-col>
                  </b-row>
                </div>
                <div class="sub-header">
                </div>
                <div class="small-text">
                  <x-table
                    :columns="columns"
                    :items="items"
                    :initial-selection="selectedItems"
                    :show-counter="false"
                    verbose-name="facture à créer"
                    verbose-name-plural="factures à créer"
                    :show-footer="true"
                    @selectionChanged="onSelectionChanged($event)"
                    id="entities"
                    responsive
                  >
                  </x-table>
                </div>
                <div class="table-footer">
                  <b-row>
                    <b-col>
                      <counter-label
                        :counter="items.length"
                        label="facture à créer"
                        labelN="factures à créer"
                      ></counter-label>
                    </b-col>
                    <b-col class="text-center">
                      <counter-label :counter="selectedItems.length" label="sélectionné">
                      </counter-label>
                    </b-col>
                    <b-col class="text-right">
                      <a
                        v-if="hasPerm('payments.add_invoice') && selectedItems.length"
                        href
                        @click.prevent="onCreate"
                        class="btn btn-xs btn-primary"
                      >
                        <i class="fa fa-arrow-alt-circle-right"></i> Facturer
                      </a>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
            <b-col v-if="activeTab.name === 'invoices'">
              <div v-if="invoices.length === 0"  class="no-items-message">
                <b>Aucune facture pour les périodes sélectionnées</b>
              </div>
              <div v-else>
                <div class="table-header">
                  <b-row>
                    <b-col>
                      <counter-label
                        :counter="invoiceItems.length"
                        label="facture"
                      ></counter-label>
                    </b-col>
                    <b-col>
                      <counter-label
                        :counter="filteredInvoiceItems.length"
                        label="visible"
                      ></counter-label>
                    </b-col>
                    <b-col class="text-center">
                      <counter-label :counter="selectedInvoices.length" label="sélectionné">
                      </counter-label>
                    </b-col>
                    <b-col class="text-right btns">
                      <a
                        href
                        v-for="action of invoiceActions"
                        :key="action.id"
                        @click.prevent="onAction(action)"
                        class="btn btn-primary btn-xs"
                      >
                        <i :class="action.icon" v-if="action.icon"></i> {{ action.label }}
                      </a>
                      <a
                        v-if="hasPerm('payments.change_invoice') && selectedInvoices.length"
                        href
                        @click.prevent="onSend"
                        class="btn btn-primary btn-xs"
                      >
                        <i class="fa fa-paper-plane"></i> Envoyer
                      </a>
                      <a
                        v-if="hasPerm('payments.view_invoice') && selectedInvoices.length"
                        href
                        @click.prevent="onContact"
                        class="btn btn-primary btn-xs"
                      >
                        <i class="fa fa-envelope"></i> Contact
                      </a>
                      <a
                        v-if="hasPerm('payments.view_invoice') && selectedInvoices.length"
                        href
                        @click.prevent="onPdfInvoices"
                        class="btn btn-secondary btn-xs"
                      >
                        <i class="fa fa-file-pdf"></i> PDF
                      </a>
                    </b-col>
                  </b-row>
                </div>
                <div class="sub-header">
                  <b-row>
                    <b-col>
                      <a
                        v-for="elt of invoiceFilters"
                        :key="elt.id"
                        href
                        class="btn btn-xs"
                        @click.prevent="selectInvoiceFilter(elt)"
                        :class="selectedInvoiceFilter === elt.id ? 'btn-primary' : 'btn-secondary'"
                      >
                        {{ elt.name }}
                      </a>
                    </b-col>
                  </b-row>
                </div>
                <div class="small-text">
                  <x-table
                    v-if="filteredInvoiceItems.length"
                    :columns="invoiceColumns"
                    :items="filteredInvoiceItems"
                    :initial-selection="selectedInvoices"
                    :show-counter="false"
                    :show-footer="true"
                    verbose-name="facture"
                    id="invoices"
                    responsive
                    @selectionChanged="onInvoicesSelectionChanged($event)"
                  >
                  </x-table>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <confirm-modal
      name="confirm-invoicing"
      title="Facturation"
      :text="confirmInvoicingText"
      @confirmed="onConfirmInvoicing()"
    ></confirm-modal>
    <send-email-modal
      name="confirm-sending"
      :title="confirmSendingText"
      :init-subject="emailSubject"
      :init-from-email="fromEmail"
      @confirmed="onConfirmSending($event)"
    ></send-email-modal>
    <payment-modal
      modal-id="pay-invoice-modal-ex"
      :entity="paymentEntity"
      :invoice="paymentInvoice"
      @paid="onPaid($event)"
    >
    </payment-modal>
    <multi-invoicing-action-modal
      id="multi-invoicing-action-modal"
      :action="selectedAction"
      :invoices="selectedInvoices"
      @done="loadInvoicing()"
    >
    </multi-invoicing-action-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment/moment'
import { mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import CounterLabel from '@/components/Controls/CounterLabel'
import TabsBar from '@/components/Controls/TabsBar.vue'
import XTable from '@/components/Controls/Table/Table'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import PageHeader from '@/components/Layout/PageHeader'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import YouthHomeFilter from '@/components/Youth/YouthHomeFilter'
import SendEmailModal from '@/components/Modals/SendEmailModal'
import ActivitiesSelect from '@/components/Activities/ActivitiesSelect'
import PaymentModal from '@/components/Payments/PaymentModal.vue'
import { dateToString, currency } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import MultiInvoicingActionModal from '@/components/Invoices/MultiInvoicingActionModal.vue'
import router from '@/router'
import store from '@/store'
import { makeChoice } from '@/types/base'
import {
  makeInvoicingLine, makeInvoice, makeCredit, makeMultiInvoicingFilter, makeMultiInvoicingAction
} from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { TabItem } from '@/types/tabs'
import { monthsAgo, getMonthRange } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { compareDates } from '@/utils/sorting'
import { sum } from '@/utils/math'

export default {
  name: 'MultiInvoicing',
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    MultiInvoicingActionModal,
    TabsBar,
    ActivitiesSelect,
    DateFrameSelector,
    SendEmailModal,
    XTable,
    CounterLabel,
    LoadingGif,
    YouthHomeFilter,
    PageHeader,
    ConfirmModal,
    PaymentModal,
  },
  props: {
    appName: String,
  },
  data() {
    return {
      multiInvoicingLoading: 'multi-invoicing',
      youthHomes: [],
      selectedYouthHomes: [],
      selectedSeanceTypes: [],
      selectedPeriods: [],
      entities: [],
      invoices: [],
      loaded: false,
      selectedItems: [],
      selectedInvoices: [],
      includeNotConfirmed: false,
      startDate: null,
      endDate: null,
      selectedSchoolYear: null,
      dateToDate: false,
      seanceFilters: [],
      selectedSeanceFilter: null,
      selectedInvoiceFilter: 0,
      selectedActivities: [],
      includeTrying: false,
      invoiceFilters: [
        makeChoice({ id: 0, name: 'Tous', }),
        makeChoice({ id: 1, name: 'À envoyer', }),
        makeChoice({ id: 2, name: 'À payer', }),
        makeChoice({ id: 3, name: 'Annulées', }),
        makeChoice({ id: 5, name: 'Dispose d\'un avoir', }),
        makeChoice({ id: 4, name: 'Inclure les factures annulées', })
      ],
      fromEmail: '',
      schoolYears: [],
      paymentEntity: null,
      paymentInvoice: null,
      remainingCredits: new Map(),
      includeAdhesions: false,
      showIncludeAdhesions: false,
      includeYouthActivities: false,
      showIncludeYouthActivities: false,
      priority: 0,
      extraColumns: [],
      extraColumnsValues: new Map(),
      activeTab: null,
      allInvoiceActions: [],
      selectedAction: null,
      dateChoice: 0,
    }
  },
  watch: {
    params: function() {
      this.loaded = false
      this.invoices = []
      this.entities = []
      this.selectedItems = []
    },
    dateToDate: function() {
      if (!this.dateToDate) {
        this.resetDateRange()
      }
    },
    dateChoice: function() {
      if (this.dateChoice === 1) {
        this.onSetLastMonthDates()
      } else if (this.dateChoice === 2) {
        this.onSetCurrentMonthDates()
      } else if (this.dateChoice === 3) {
        this.onSetDatesFromPeriods()
      }
    },
  },
  computed: {
    tabs() {
      const todoCount = this.entities.length
      const invoicesCount = this.invoices.length
      let label1 = 'Pas de famillles à facturer'
      if (todoCount > 0) {
        label1 = (todoCount > 1) ? '' + todoCount + ' familles à facturer' : '1 famille à facturer'
      }
      let label2 = 'Pas de factures existantes'
      if (invoicesCount > 0) {
        label2 = (invoicesCount > 1) ? '' + invoicesCount + ' factures existantes' : '1 facture existante'
      }
      return [
        new TabItem('todo', label1, 'fa fa-file-invoice'),
        new TabItem('invoices', label2, 'fa fa-list')
      ]
    },
    canAccess() {
      if (this.appName === 'adhesions') {
        return this.hasAllPerms(['payments.add_invoice', 'adhesions.view_adhesion'])
      } else if (this.appName === 'activities') {
        return this.hasAllPerms(['payments.add_invoice', 'activities.view_coreactivity'])
      } else if (this.appName === 'youth') {
        return this.hasAllPerms(['payments.add_invoice', 'youth.view_seanceinscription'])
      } else if (this.appName === 'nursery') {
        return this.hasAllPerms(['payments.add_invoice', 'nursery.view_nurseryinscription'])
      }
      return false
    },
    invoiceActions() {
      if (this.hasPerm('payments.change_invoice') && this.selectedInvoices.length) {
        return this.allInvoiceActions
      }
      return []
    },
    showYouthFilter() {
      return this.appName === 'youth'
    },
    showActivitiesFilter() {
      return this.appName === 'activities'
    },
    isNursery() {
      return this.appName === 'nursery'
    },
    canChangeDate() {
      return (this.dateChoice === 0)
    },
    showView() {
      if (this.showYouthFilter || this.showActivitiesFilter) {
        return this.params
      }
      return true
    },
    pageLabel() {
      if (this.appName === 'youth') {
        return store.getters.youthHomeLabel
      } else if (this.appName === 'adhesions') {
        return 'Adhésions'
      } else if (this.appName === 'activities') {
        return 'Activités'
      } else if (this.appName === 'nursery') {
        return 'Crèche'
      }
      return ''
    },
    firstCol() {
      if ((this.invoiceItems.length > 0) && (this.items.length === 0)) {
        return 3
      }
      if ((this.invoiceItems.length === 0) && (this.items.length > 0)) {
        return 9
      }
      if (this.priority !== 0) {
        return (this.priority < 0) ? 8 : 4
      }
      return 6
    },
    lastCol() {
      if ((this.invoiceItems.length > 0) && (this.items.length === 0)) {
        return 9
      }
      if ((this.invoiceItems.length === 0) && (this.items.length > 0)) {
        return 3
      }
      if (this.priority !== 0) {
        return (this.priority > 0) ? 8 : 4
      }
      return 6
    },
    showPriority() {
      return (this.invoiceItems.length > 0) && (this.items.length > 0)
    },
    confirmInvoicingText() {
      if (this.selectedItems.length > 1) {
        return 'Souhaitez-vous facturer ces ' + this.selectedItems.length + ' familles?'
      } else {
        return 'Souhaitez-vous facturer cette famille'
      }
    },
    confirmSendingText() {
      if (this.selectedInvoices.length > 1) {
        return 'Souhaitez-vous envoyer ces ' + this.selectedInvoices.length + ' factures?'
      } else {
        return 'Souhaitez-vous envoyer cette facture'
      }
    },
    params() {
      let params = {}
      if (this.appName === 'youth') {
        params = {
          'youth_homes': this.selectedYouthHomes.map(elt => elt.id).join('-'),
          'seance_types': this.selectedSeanceTypes.map(elt => elt.id).join('-'),
          'periods': this.selectedPeriods.map(elt => elt.id).join('-'),
          'include_not_confirmed': this.includeNotConfirmed ? '1' : '0',
          'include_youth_activities': (this.showIncludeYouthActivities && this.includeYouthActivities) ? '1' : '0',
        }
        const values = Object.entries(params).map(([key, elt]) => elt)
        const isValid = values.filter((elt) => elt).length === values.length
        if (!isValid) {
          return ''
        }
        if (this.selectedSeanceFilter) {
          params['seance_filter'] = this.selectedSeanceFilter.id
        }
      }
      if (this.appName === 'activities') {
        params = {
          'activities': this.selectedActivities.map(elt => elt.id).join('-'),
          'include_trying': this.includeTrying ? '1' : '0',
        }
        const values = Object.entries(params).map(([key, elt]) => elt)
        const isValid = values.filter((elt) => elt).length === values.length
        if (!isValid) {
          return ''
        }
      }
      if (this.startDate) {
        params['start_date'] = dateToString(this.startDate, 'YYYY-MM-DD')
      }
      if (this.endDate) {
        params['end_date'] = dateToString(this.endDate, 'YYYY-MM-DD')
      }
      if (this.showIncludeAdhesions && this.includeAdhesions) {
        params['include_adhesions'] = true
      }
      return new URLSearchParams(params).toString()
    },
    validInvoiceItems() {
      return this.invoiceItems.filter(
        elt => !elt.isCancelled
      )
    },
    filteredInvoiceItems() {
      if (this.selectedInvoiceFilter === 1) {
        // à envoyer
        return this.validInvoiceItems.filter(
          elt => !elt.isPaid && !elt.isSent
        )
      }
      if (this.selectedInvoiceFilter === 2) {
        // à payer
        return this.validInvoiceItems.filter(
          elt => !elt.isPaid
        )
      }
      if (this.selectedInvoiceFilter === 3) {
        // annulées
        return this.invoiceItems.filter(
          elt => elt.isCancelled
        )
      }
      if (this.selectedInvoiceFilter === 4) {
        // inclus les annulées
        return this.invoiceItems
      }
      if (this.selectedInvoiceFilter === 5) {
        // à payer + avoirs en cours
        return this.validInvoiceItems.filter(
          elt => !elt.isPaid && this.getRemainingCredits(elt.entity.id)
        )
      }
      return this.validInvoiceItems
    },
    hasFilter() {
      return this.selectedInvoiceFilter > 0
    },
    columns() {
      const columns = [
        { selector: true, 'name': 'selector', },
        {
          name: 'entityName',
          label: 'Famille',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.id)
          },
          linkUrl: item => {
            return router.resolve({ name: 'families-detail', params: { entityId: item.id, }, }).href
          },
          colFooter: 'Total',
        },
        {
          name: 'count',
          label: 'Nombre de ventes',
          alignCenter: true,
          number: true,
          colFooterSum: true,
        },
        {
          name: 'sum',
          label: '€',
          alignRight: true,
          currency: true,
          colFooterSum: true,
        }
      ]
      for (const extraCol of this.extraColumns) {
        if (extraCol.forTodo()) {
          columns.push(
            {
              name: 'extra' + extraCol.field.id,
              label: extraCol.label,
            }
          )
        }
      }
      return columns
    },
    invoiceColumns() {
      const columns = [
        { selector: true, 'name': 'selector', },
        {
          name: 'invoiceNumber',
          label: '',
          isLink: item => {
            return (item.id)
          },
          linkUrl: item => {
            return router.resolve({ name: 'invoice-detail', params: { invoiceId: '' + item.id, }, }).href
          },
        },
        {
          name: 'entityName',
          label: 'Famille',
          onClick: item => {
            this.showEntitySidebar(item.entity)
          },
          isLink: item => {
            return (item.entityId)
          },
          linkUrl: item => {
            return router.resolve({ name: 'families-detail', params: { entityId: item.entityId, }, }).href
          },
        },
        {
          name: 'date',
          label: 'Date',
          dateFormat: 'L',
        },
        {
          name: 'status',
          label: 'Etat',
          contentCallback: this.invoiceStatusHtml,
        },
        {
          name: 'amount',
          label: '€',
          currency: true,
          colFooterSum: true,
        },
        {
          name: 'pay',
          label: '',
          hideFilter: true,
          onClick: item => {
            if (!item.isCancelled && !item.isPaid) {
              this.payInvoice(item)
            }
          },
          isLink: item => {
            return (!item.isCancelled && !item.isPaid)
          },
          contentCallback: (col, item) => {
            if (!item.isCancelled && !item.isPaid) {
              return '<span class="btn btn-secondary btn-xs">' +
                '<i class="fa fa-money-bill")></i> Payer' +
                '</span>'
            }
            return ''
          },
        },
        {
          name: 'totalPrice',
          label: '',
          alignRight: true,
          currency: true,
          colFooterSum: true,
        }
      ]
      for (const extraCol of this.extraColumns) {
        if (extraCol.forInvoices()) {
          columns.push(
            {
              name: 'extra' + extraCol.field.id,
              label: extraCol.label,
            }
          )
        }
      }
      return columns
    },
    items() {
      return this.entities.map(
        elt => this.makeItem(elt)
      )
    },
    invoiceItems() {
      return this.invoices.map(
        elt => this.makeInvoiceItem(elt)
      )
    },
    emailSubject() {
      let text = 'Votre facture'
      if (this.selectedYouthHomes.length === 1) {
        text += ' ' + this.selectedYouthHomes[0].name
      }
      const periodNames = this.selectedPeriods.map(elt => elt.name).join(' ')
      text += ' ' + periodNames
      return text
    },
  },
  async mounted() {
    this.activeTab = this.tabs[0]
    await this.loadInvoicingOptions()
    await this.loadSeanceFilters()
    this.dateToDate = this.isNursery
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    onFilterChanged(event) {
      this.selectedYouthHomes = event.youthHomes
      this.selectedSeanceTypes = event.seanceTypes
      this.selectedPeriods = event.periods
      if (this.dateToDate && this.dateChoice === 3) {
        this.onSetDatesFromPeriods()
      }
    },
    onActivitiesChanged(event) {
      this.selectedActivities = event.activities
    },
    onCreate() {
      if (this.selectedItems.length) {
        this.$bvModal.show('bv-confirm-modal:confirm-invoicing')
      }
    },
    onSend() {
      if (this.selectedInvoices.length) {
        this.$bvModal.show('bv-send-email-modal:confirm-sending')
      }
    },
    async onPdfInvoices() {
      if (this.selectedInvoices.length) {
        const docUrl = '/factures-batch/<key>/pdf/'
        const docSlug = 'facturation-' + moment().format('YYYY-MM-DD-HH-MM-SS')
        const docContent = this.selectedInvoices.map(elt => '' + elt.id).join('-')
        try {
          await openDocument(docUrl, docSlug, docContent)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getRemainingCredits(entityId) {
      const key = '' + entityId
      if (this.remainingCredits.has(key)) {
        const credits = this.remainingCredits.get(key)
        if (credits.length) {
          let text = ''
          if (credits.length === 1) {
            text = '1 avoir'
          } else {
            text = '' + credits.length + ' avoirs'
          }
          const sumCredits = sum(credits)
          text += ' pour ' + currency(sumCredits)
          return text
        }
      }
      return ''
    },
    getExtraColumnsValues(fieldId, entityId) {
      const key = '' + fieldId + ':' + entityId
      if (this.extraColumnsValues.has(key)) {
        return this.extraColumnsValues.get(key)
      }
      return ''
    },
    async onPaid(event) {
      const invoices = event.invoices
      const invoiceIds = this.invoices.map(elt => elt.id)
      for (const invoice of invoices) {
        const index = invoiceIds.indexOf(invoice.id)
        if (index >= 0) {
          this.invoices[index] = invoice
        }
      }
      await this.loadRemainingCredits(this.paymentEntity)
      this.invoices = [].concat(this.invoices)
    },
    async loadInvoicing() {
      const params = this.params
      if (this.showView) {
        this.loaded = false
        this.entities = []
        this.invoices = []
        this.selectedItems = []
        this.selectedInvoices = []
        const remainingCredits = new Map()
        const extraColumnsValues = new Map()
        this.startLoading(this.multiInvoicingLoading)
        let url = '/api/' + this.appName + '/invoicing/?' + params
        if (this.appName === 'nursery') {
          url = '/nursery/api/invoicing/?' + params
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.entities = resp.data.entities.map(elt => makeInvoicingLine(elt))
          this.invoices = resp.data.invoices.map(elt => makeInvoice(elt))
          this.fromEmail = resp.data['from_email']
          this.loaded = true
          for (const [entityId, credits] of Object.entries(resp.data['remaining_credits'])) {
            remainingCredits.set(entityId, credits)
          }
          this.remainingCredits = remainingCredits
          const columns = resp.data['filter_fields'] || []
          this.extraColumns = columns.map(makeMultiInvoicingFilter)
          for (const [key, fieldValue] of Object.entries(resp.data['filter_fields_values'])) {
            extraColumnsValues.set(key, fieldValue)
          }
          this.extraColumnsValues = extraColumnsValues
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.multiInvoicingLoading)
      }
    },
    async loadRemainingCredits(entity) {
      if (entity) {
        let url = '/api/entity/' + entity.id + '/credits/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const credits = resp.data.map(makeCredit).map(crd => crd.remainingAmount)
          const remainingCredits = new Map(this.remainingCredits)
          remainingCredits.set('' + entity.id, credits)
          this.remainingCredits = new Map(remainingCredits)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadSeanceFilters() {
      if (this.appName === 'youth') {
        const backendApi = new BackendApi('get', '/api/youth/invoicing-filters/')
        try {
          let resp = await backendApi.callApi()
          const seanceFilters = resp.data.map(elt => makeChoice(elt))
          if (seanceFilters.length) {
            this.seanceFilters = [
              makeChoice({ id: 0, name: 'Toutes les séances', })
            ].concat(seanceFilters)
            this.selectedSeanceFilter = this.seanceFilters[0]
          } else {
            this.seanceFilters = []
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadInvoicingOptions() {
      const backendApi = new BackendApi('get', '/api/multi-invoicing-options/')
      try {
        let resp = await backendApi.callApi()
        const schoolYears = resp.data['school_years'].map(elt => makeSchoolYear(elt))
        if (schoolYears.length) {
          this.selectedSchoolYear = schoolYears[0]
        }
        this.schoolYears = schoolYears
        this.showIncludeYouthActivities = resp.data['include_youth_activities']
        this.showIncludeAdhesions = resp.data['include_adhesions']
        this.allInvoiceActions = (resp.data['invoice_actions'] || []).map(makeMultiInvoicingAction)
        this.allInvoiceActions = [
          {
            id: -1,
            label: 'Utiliser les avoirs',
            url: '/api/use-remaining-credits/',
            icon: 'fa fa-credit-card',
            fields: [],
          }
        ].concat(this.allInvoiceActions)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    makeItem(elt) {
      const item = {
        id: elt.entity.id,
        entityName: elt.entity.name,
        entity: elt.entity,
        count: elt.count,
        sum: elt.sum,
      }
      for (const col of this.extraColumns) {
        item['extra' + col.field.id] = this.getExtraColumnsValues(col.field.id, elt.entity.id)
      }
      return item
    },
    makeInvoiceItem(elt) {
      const item = {
        id: elt.id,
        entity: elt.entity,
        entityName: elt.entity.name,
        entityId: elt.entity.id,
        invoiceNumber: '' + elt.invoiceNumber,
        totalPrice: elt.totalPrice,
        date: elt.createdOn,
        status: elt.status,
        amount: elt.toBePaidPrice(),
        style: elt.getStyle(),
        isSent: elt.isSent,
        isPaid: elt.isPaid,
        isCancelled: elt.isCancelled,
        invoice: elt,
        pay: 1,
      }
      for (const col of this.extraColumns) {
        item['extra' + col.field.id] = this.getExtraColumnsValues(col.field.id, elt.entity.id)
      }
      return item
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'facturation-' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'facturation' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onSelectionChanged(event) {
      this.selectedItems = event.items
    },
    onInvoicesSelectionChanged(event) {
      this.selectedInvoices = event.items
    },
    async onConfirmSending(event) {
      const invoiceIds = this.selectedInvoices.map(elt => elt.id)
      this.startLoading(this.multiInvoicingLoading)
      let url = '/api/invoice-email-batch/'
      const data = {
        invoices: invoiceIds,
        subject: event.subject,
        body: event.body,
        sender: event.fromEmail,
      }
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi(data)
        const sentCount = resp.data.sent.length
        const notSentCount = resp.data['not_sent'].length
        if (sentCount) {
          let text = ''
          if (sentCount > 1) {
            text += '' + sentCount + ' emails ont été envoyés'
          } else {
            text += 'Un email a été envoyé'
          }
          await this.addSuccess(text)
        }
        if (notSentCount) {
          let text = 'Adresse email manquante ou pas de contact par email: '
          if (notSentCount > 1) {
            text += '' + notSentCount + ' emails n\'ont pas pu être envoyés'
          } else {
            text += 'Un email n\'a pas pu être envoyé'
          }
          await this.addWarning(text)
        }
        await this.loadInvoicing()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.multiInvoicingLoading)
    },
    async onConfirmInvoicing() {
      const params = this.params
      const entities = this.selectedItems.map(elt => elt.id)
      if (this.showView) {
        this.loaded = false
        this.startLoading(this.multiInvoicingLoading)
        let url = '/api/' + this.appName + '/invoicing/?' + params
        if (this.appName === 'nursery') {
          url = '/nursery/api/invoicing/?' + params
        }
        const backendApi = new BackendApi('post', url)
        try {
          await backendApi.callApi({ entities: entities, })
          await this.loadInvoicing()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.multiInvoicingLoading)
      }
    },
    invoiceStatusHtml(value, item) {
      let status1 = '<div class="badge ' + item.style + '">' + value + '</div>'
      let credits2 = ''
      if (!item.isCancelled && !item.isPaid) {
        credits2 = this.getRemainingCredits(item.entity.id)
        if (credits2) {
          credits2 = '<div class="small-text">' + credits2 + '</div>'
        }
      }
      return status1 + credits2
    },
    getLinks() {
      let isActive = false
      if (this.activeTab === this.tabs[1]) {
        isActive = this.invoices.length > 0
      } else {
        isActive = this.entities.length > 0
      }
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: isActive ? 'btn-secondary' : 'btn-secondary disabled',
        }
      ]
    },
    resetDateRange() {
      this.startDate = null
      this.endDate = null
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
    onSetDatesFromPeriods() {
      if (this.selectedSchoolYear) {
        let periodDates = []
        for (const period of this.selectedPeriods) {
          periodDates = periodDates.concat(period.getTimeframe(this.selectedSchoolYear.id))
        }
        periodDates = periodDates.sort((elt1, elt2) => compareDates(elt1, elt2))
        if (periodDates.length > 1) {
          this.startDate = periodDates[0]
          this.endDate = periodDates[periodDates.length - 1]
        } else {
          this.startDate = null
          this.endDate = null
        }
      } else {
        this.startDate = null
        this.endDate = null
      }
    },
    onSetLastMonthDates() {
      const lastMonth = monthsAgo(moment(), -1)
      const range = getMonthRange(lastMonth)
      if (range.length >= 2) {
        this.startDate = range[0]
        this.endDate = range[1]
      }
    },
    onSetCurrentMonthDates() {
      const range = getMonthRange(moment())
      if (range.length >= 2) {
        this.startDate = range[0]
        this.endDate = range[1]
      }
    },
    selectInvoiceFilter(elt) {
      this.selectedInvoiceFilter = elt.id
    },
    payInvoice(item) {
      this.paymentEntity = item.entity
      this.paymentInvoice = item.invoice
      this.$nextTick(
        () => {
          this.$bvModal.show('pay-invoice-modal-ex')
        }
      )
    },
    async onContact() {
      if (this.selectedInvoices.length) {
        const entities = this.selectedInvoices.map(invoice => invoice.entity.id)
        this.contactEntities(entities)
      }
    },
    contactEntities(entities) {
      router.push(
        {
          name: 'contact-entities',
          query: { ids: entities.map(elt => '' + elt).join('-'), },
        }
      )
    },
    onTabChanged(tab) {
      this.activeTab = tab
    },
    onAction(action) {
      this.selectedAction = action
      this.$nextTick(
        function() {
          this.$bvModal.show('multi-invoicing-action-modal')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.table-header, .table-footer {
  margin-top: 10px;
  padding: 5px 10px;
  background: #454851;
  color: #fff;
  height: 50px;
  border-bottom: solid 2px #fff;
  div {
    font-weight: bold;
    font-size: 16px;
  }
}
.table-footer {
  margin-top: -15px;
}
.sub-header {
  padding: 5px;
  height: 50px;
  background: #454851;
  margin-bottom: 0;
  a {
    margin-right: 5px;
  }
  a.selected {
    color: #ffff00;
  }
}
.badge {
  color: #000;
  display: block;
  margin-bottom: 2px;
}
.badge a {
  color: #000 !important;
}
.no-items-message {
  margin-top: 10px;
  padding: 10px;
  background: #f2f2a2;
}
.date-to-date-selector a {
  font-size: 12px;
}
.date-to-date-selector a.disabled {
  color: #ccc !important;
}
.priority-table-btn a {
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  color: #fff !important;
  background: #444;
  padding: 4px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
}
</style>

<template>
  <div v-if="entity !== null && hasPerm('youth.view_seanceinscription')">
    <div
      class="header-line clickable"
      :style="getHeaderStyle(elt)"
      @click.prevent="showSeances"
    >
      <b-row>
        <b-col cols="10">
          <b>{{ elt.period.name }} {{ elt.schoolYear.name }}</b>
          <b v-if="elt.seanceType.id"> - {{ elt.seanceType.name }}</b>
          <b v-if="elt.youthHome.id"> - {{ elt.youthHome.name }}</b>
        </b-col>
        <b-col cols="2" class="text-right">
          <span
            :class="!visible ? 'btn-primary' : 'btn-secondary'"
            class="btn btn-sm"
            :title="'' + filteredInscriptions.length"
          >
            <span v-if="visible && filteredInscriptions.length !== elt.allInscriptionsCount">
              {{ filteredInscriptions.length }} /
            </span>
            {{ elt.allInscriptionsCount }}
          </span>
        </b-col>
      </b-row>
    </div>
    <div v-show="visible">
      <div class="field-line">
        <b-row>
          <b-col>
            <check-box-select
              inline
              :id="'filter-codes' + elt.index"
              :choices="seanceNames"
              :initial-value="seanceNames"
              @changed="onSeancesChanged"
              @init="onSeancesChanged"
            >
            </check-box-select>
          </b-col>
        </b-row>
      </div>
      <div class="field-line">
        <b-row>
          <b-col>
            <div v-if="individuals.length">
              <check-box-select
                inline
                :id="'filter-individuals' + elt.index"
                :choices="individuals"
                :initial-value="individuals"
                :name-callback="individualNameCallback"
                @changed="onIndividualsChanged"
                @init="onIndividualsChanged"
              >
              </check-box-select>
            </div>
          </b-col>
          <b-col cols="4">
            <b-select v-model="selectAllOptionId" id="selectAllOptionId" class="small-select">
              <b-select-option :value="0">Toutes les inscriptions</b-select-option>
              <b-select-option :value="1">Sauf annulations</b-select-option>
              <b-select-option :value="2">Payantes</b-select-option>
              <b-select-option :value="3">Sauf absences</b-select-option>
              <b-select-option :value="4">Sauf annulations et absences</b-select-option>
              <b-select-option :value="5">Annulations</b-select-option>
              <b-select-option :value="6">Absences</b-select-option>
              <b-select-option :value="7">Annulations et absences</b-select-option>
            </b-select>
          </b-col>
        </b-row>
      </div>
      <div>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div v-if="!isLoading(loadingName)">
          <div v-if="filteredInscriptions.length === 0" class="empty-text">
            <div v-if="inscriptions.length === 0">Aucune inscription pour cette période</div>
            <div v-else>Aucune inscription ne correspond au filtre sélectionné</div>
          </div>
          <div v-else>
            <div class="field-line">
              <b-row>
                <b-col cols="2">
                  <b-checkbox
                    :checked="areAllInscriptionsSelected()"
                    :value="true"
                    :unchecked-value="false"
                    @change="selectAllInscriptions($event)"
                  >
                    Tout cocher
                  </b-checkbox>
                </b-col>
                <b-col class="text-right">
                  <youth-extra-sales-button
                    :inscriptions="selectedInscriptions"
                    :entity="entity"
                    @done="loadInscriptions()"
                  >
                  </youth-extra-sales-button>
                </b-col>
              </b-row>
            </div>
            <div
              v-for="inscription in filteredInscriptions"
               :key="inscription.id"
               class="field-line"
            >
              <b-row>
                <b-col col="8" v-if="useSaleLabel && inscription.sale">
                  <b-checkbox
                    :checked="isInscriptionSelected(inscription)"
                    @change="selectInscription(inscription, $event)"
                  >
                    {{ inscription.sale.label }}
                  </b-checkbox>
                </b-col>
                <b-col cols="5" v-if="!(useSaleLabel && inscription.sale)">
                  <b-checkbox
                    :checked="isInscriptionSelected(inscription)"
                    @change="selectInscription(inscription, $event)"
                  >
                    <span class="badge badge-light">{{ inscription.createdOn | dateToString }}</span>
                    <span v-if="inscription.multiplier > 1">
                      x {{ inscription.multiplier }}
                    </span>
                    <span
                      class="badge badge-light"
                      v-for="worskhop in inscription.workshops"
                      :key="worskhop.id"
                    >
                      {{ worskhop.name }}
                    </span>
                    {{ inscription.seance.name }}
                  </b-checkbox>
                  <div v-if="inscription.entity.id !== entity.id">
                    <span class="badge badge-danger">
                      inscription par famille {{ inscription.entity.name }}
                    </span>
                  </div>
                  <div v-if="inscription.comments" class="inscription-comments">
                    {{ inscription.comments }}
                  </div>
                </b-col>
                <b-col cols="3" v-if="!(useSaleLabel && inscription.sale)">
                  {{ inscription.individual.firstName }}
                </b-col>
                <b-col cols="2">
                  <span
                    v-if="inscription.sale"
                    :class="{ignored: inscription.sale.ignored, }"
                    :title="inscription.familyLevel ? ('QF: ' + inscription.familyLevel) : ''"
                  >
                    {{ inscription.sale.price | currency }}
                  </span>
                  <span v-else-if="inscription.reward" :class="{ignored: inscription.reward.cancelled, }">
                    {{ inscription.reward.price | currency }}
                  </span>
                  <span v-else>0 €</span>
                  &nbsp;
                  <span v-if="inscription.paidBy" class="badge badge-dark">
                    forfait
                  </span>
                  <div v-if="inscription.sale">
                    <discount-badge
                      v-for="discount of inscription.sale.discounts"
                      :key="discount.id"
                      :discount="discount"
                    >
                    </discount-badge>
                  </div>
                </b-col>
                <b-col cols="2" class="text-right">
                  <div v-if="!inscription.confirmed">
                    <div
                      class="inscription-status inscription-status-cancelled-not-confirmed"
                      v-if="inscription.cancelledOn"
                      v-b-tooltip="'annulée le ' + inscription.cancelledOnAsStr()"
                    >
                      Annulation à confirmer
                    </div>
                    <div v-else class="inscription-status inscription-status-not-confirmed">
                      À confirmer
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="inscription-status inscription-status-cancelled"
                      v-if="inscription.cancelledOn"
                      v-b-tooltip="'annulée le ' + inscription.cancelledOnAsStr()"
                    >
                      <span v-if="!inscription.sale">
                        Annulation
                      </span>
                      <span v-else-if="inscription.sale.cancellation">
                        <span v-if="!inscription.sale.cancellationConfirmed">
                          Annulation en attente de confirmation
                        </span>
                        <span v-else-if="inscription.sale.refunded">
                          Annulation
                          <span
                            class="small-text"
                            v-b-tooltip="'remboursée le ' + getDate(inscription.sale.cancelledOn)"
                          >
                            remboursée
                          </span>
                        </span>
                        <span
                          v-else-if="inscription.sale.ignored"
                        >
                          Annulation
                        </span>
                        <span v-else>
                          Annulation payante
                          <div>
                            <a class="small-link" href @click.prevent="onRefundSale(inscription)">
                              Rembourser
                            </a>
                          </div>
                        </span>
                      </span>
                      <span v-else>Annulée</span>
                    </div>
                    <div
                      class="inscription-status"
                      :class="inscription.waiting ? 'inscription-status-waiting' : 'inscription-status-refused'"
                      v-else-if="inscription.refusedOn"
                      :title="'le ' + inscription.refusedOnAsStr()"
                    >
                      <span v-if="inscription.waiting">Liste d'attente</span>
                      <span v-else>Refusée</span>
                    </div>
                    <div v-else>
                      <div>
                        <absence-indicator
                          :individual="inscription.individual"
                          :inscription="inscription"
                          @updated="loadInscriptions()"
                          :show-label="true"
                        >
                        </absence-indicator>
                      </div>
                      <presence-hours :inscription="inscription"></presence-hours>
                    </div>
                  </div>
                  <span v-if="inscription.sale">
                    <invoice-badge
                      :invoice="inscription.sale.invoice"
                      v-if="inscription.sale.showInvoice()"
                      :entity="entity"
                    >
                    </invoice-badge>
                  </span>
                  <span v-if="inscription.reward" class="badge badge-dark">
                    Chantier
                  </span>
                </b-col>
              </b-row>
              <div v-for="extraSale of inscription.extraSales" :key="extraSale.id" class="extra-sale-line">
                <b-row>
                  <b-col>
                    <div style="margin-left: 20px">
                      <i class="fa fa-chevron-right"></i>&nbsp;
                      <span class="badge badge-light">{{ extraSale.createdOn | dateToString }}</span>
                      {{ extraSale.shortLabel | defaultValue(extraSale.label) }}
                      <a
                        href
                        @click.prevent="cancelExtraSale(extraSale)"
                        class="cancel-extra-button"
                        v-if="!extraSale.ignored"
                      >
                        <i class="fa fa-close"></i> Annuler
                      </a>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <span :class="{ignored: extraSale.ignored, }">
                      {{ extraSale.price | currency }}
                    </span>
                    <discount-badge
                      v-for="discount of extraSale.discounts"
                      :key="discount.id"
                      :discount="discount"
                    >
                    </discount-badge>
                  </b-col>
                  <b-col cols="2">
                    <invoice-badge
                      :invoice="extraSale.invoice"
                      :only-number="true"
                      :entity="entity"
                      v-if="extraSale.showInvoice()"
                    >
                    </invoice-badge>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="field-line btns text-right">
            <b-row>
              <b-col cols="3" class="text-left">
                <b-checkbox v-model="useSaleLabel" class="small-text">
                  Utiliser le libellé des ventes
                </b-checkbox>
              </b-col>
              <b-col>
                <div>
                  <a class="btn btn-sm btn-secondary" href @click.prevent="printProposal(elt)">
                    <i class="fa fa-cart-shopping"></i> Devis
                  </a>
                  <a
                    class="btn btn-sm btn-secondary"
                    href
                    @click.prevent="printJustificative(elt)"
                    title="Seuls les inscriptions confirmées sont prises en compte"
                    v-b-tooltip
                    :class="{ disabled: confirmedCount === 0 }"
                  >
                    <i class="fa fa-file-pdf"></i> Justificatif
                  </a>
                </div>
                <div>
                  <b-checkbox v-if="adhesions.length" v-model="includeAdhesions" class="small-text">
                    Inclure les adhésions
                  </b-checkbox>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <confirm-modal
      :name="refundSaleModalName"
      title="Rembourser une annulation payante"
      :text="confirmRefundText"
      :object="refundingInscription"
      @confirmed="onConfirmRefundSale"
    ></confirm-modal>
    <confirm-modal
      :name="cancelSaleModalName"
      :title="cancelSaleModalTitle"
      :text="cancelSaleModalText"
      :object="cancellingSale"
      @confirmed="onConfirmCancelExtraSale"
    ></confirm-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import DiscountBadge from '@/components/Discounts/DiscountBadge.vue'
import InvoiceBadge from '@/components/Invoices/InvoiceBadge'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import AbsenceIndicator from '@/components/Youth/AbsenceIndicator'
import YouthExtraSalesButton from '@/components/Youth/YouthExtraSalesButton'
import PresenceHours from '@/components/Youth/PresenceHours'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeSeanceInscription, getPeriodHeaderStyle } from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import { filterDay, filterSeance } from '@/utils/youth'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect.vue'
import { existsIn, distinct } from '@/utils/arrays'

export default {
  name: 'entity-inscriptions-list',
  components: {
    CheckBoxSelect,
    DiscountBadge,
    AbsenceIndicator,
    YouthExtraSalesButton,
    InvoiceBadge,
    LoadingGif,
    PresenceHours,
    ConfirmModal,
  },
  mixins: [BackendMixin],
  props: {
    entity: Object,
    elt: Object,
    seancesFilter: Object,
    adhesions: Array,
  },
  data() {
    return {
      loaded: false,
      visible: false,
      loadingName: '',
      inscriptions: [],
      inscriptionsSelection: new Map(),
      confirmRefundText: '',
      refundingInscription: null,
      cancellingSale: null,
      cancelSaleModalText: '',
      selectAllOptionId: 0,
      seanceNames: [],
      selectedSeances: [],
      selectedIndividuals: [],
      includeAdhesions: false,
      useSaleLabel: false,
    }
  },
  computed: {
    canChange() {
      return this.hasPerm('youth.add_seanceinscription')
    },
    selectedInscriptions() {
      return this.filteredInscriptions.filter(elt => this.isInscriptionSelected(elt))
    },
    refundSaleModalName() {
      return 'refund-sale' + this.elt.period.id + '-' + this.elt.schoolYear.id + '-' +
        this.elt.seanceType.id + '-' + this.elt.youthHome.id
    },
    cancelSaleModalName() {
      return 'cancel-sale' + this.elt.period.id + '-' + this.elt.schoolYear.id + '-' +
        this.elt.seanceType.id + '-' + this.elt.youthHome.id
    },
    cancelSaleModalTitle() {
      if (this.cancellingSale) {
        if (this.cancellingSale.price > 0) {
          return 'Annuler le supplément'
        } else {
          return 'Annuler la réduction'
        }
      }
      return ''
    },
    filteredInscriptions() {
      let inscriptions = this.inscriptions.filter(
        ins => filterDay(this.seancesFilter, ins.seance.date) && filterSeance(this.seancesFilter, ins.seance)
      ).filter(
        ins => {
          const code = ins.seance.getBaseName()
          return existsIn([code], this.selectedSeances)
        }
      ).filter(
        ins => {
          const individual = ins.individual.id
          const individualIds = this.selectedIndividuals.map(elt => elt.id)
          return existsIn([individual], individualIds)
        }
      )
      if ((this.selectAllOptionId === 1) || (this.selectAllOptionId === 4)) {
        inscriptions = inscriptions.filter(ins => !ins.cancelled)
      }
      if ((this.selectAllOptionId === 3) || (this.selectAllOptionId === 4)) {
        inscriptions = inscriptions.filter(ins => !ins.absence)
      }
      if (this.selectAllOptionId === 2) {
        inscriptions = inscriptions.filter(ins => ins.sale && !ins.sale.ignored)
      }
      if (this.selectAllOptionId === 5) {
        inscriptions = inscriptions.filter(ins => ins.cancelled)
      }
      if (this.selectAllOptionId === 6) {
        inscriptions = inscriptions.filter(ins => ins.absence)
      }
      if (this.selectAllOptionId === 7) {
        inscriptions = inscriptions.filter(ins => ins.cancelled || ins.absence)
      }
      return inscriptions
    },
    confirmedCount() {
      return this.filteredInscriptions.filter(elt => elt.confirmed).length
    },
    individuals() {
      return distinct(
        this.inscriptions.map(
          elt => elt.individual
        )
      )
    },
  },
  created() {
    this.loadingName = 'entity-inscriptions-' + this.elt.index
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getHeaderStyle(elt) {
      return getPeriodHeaderStyle(elt)
    },
    filterDate(seance) {
      return filterDay(this.seancesFilter, seance.date)
    },
    async showSeances() {
      this.visible = !this.visible
      if (this.visible) {
        this.startLoading(this.loadingName)
        await this.loadInscriptions()
        this.loaded = true
        this.endLoading(this.loadingName)
      }
    },
    async loadInscriptions() {
      if (this.entity && this.entity.id > 0) {
        const youthHomeId = this.elt.youthHome.id
        const typeId = this.elt.seanceType.id
        const periodId = this.elt.period.id
        const entityId = this.entity.id
        let url
        if (youthHomeId) {
          url = '/api/youth/entity-seance-inscriptions/' + entityId + '/' + youthHomeId + '/' + typeId + '/'
          url += periodId + '/'
        } else {
          url = '/api/youth/entity-seance-full-inscriptions/' + entityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        try {
          let resp = await backendApi.callApi()
          this.inscriptions = resp.data.map(elt => makeSeanceInscription(elt))
          this.seanceNames = distinct(
            this.inscriptions.map(
              ins => {
                const code = ins.seance.getBaseName()
                return { id: code, name: code, }
              }
            )
          )
          this.inscriptionsSelection.clear()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    isInscriptionSelected(inscription) {
      return !!this.inscriptionsSelection.get(inscription.id)
    },
    selectInscription(inscription, value) {
      this.inscriptionsSelection.set(inscription.id, value)
      this.inscriptionsSelection = new Map(this.inscriptionsSelection)
    },
    areAllInscriptionsSelected() {
      for (const inscription of this.filteredInscriptions) {
        if (!this.inscriptionsSelection.get(inscription.id)) {
          return false
        }
      }
      return true
    },
    selectAllInscriptions(value) {
      for (const inscription of this.filteredInscriptions) {
        this.inscriptionsSelection.set(inscription.id, value)
      }
      this.inscriptionsSelection = new Map(this.inscriptionsSelection)
    },
    printProposal(elt) {
      this.printDoc(elt, '/devis-inscriptions-seances/pdf/<key>/')
    },
    printJustificative(elt) {
      this.printDoc(elt, '/justificatif-inscriptions-seances/pdf/<key>/')
    },
    async printDoc(elt, docUrl) {
      let ids
      if (elt.youthHome.id) {
        ids = [this.entity.id, elt.youthHome.id, elt.seanceType.id, elt.period.id]
      } else {
        ids = [this.entity.id, 0, 0, 0]
      }
      ids.push(this.includeAdhesions ? 1 : 0)
      const docSlug = '' + ids.map(elt => '' + elt).join('-')
      let docContent = ''
      let inscriptions = this.selectedInscriptions.map(elt => elt.id)
      if (inscriptions.length === 0) {
        inscriptions = this.filteredInscriptions.map(elt => elt.id)
      }
      docContent = inscriptions.map(elt => '' + elt).join('-')
      let fullDocUrl = docUrl
      try {
        let saleParam = this.useSaleLabel ? 1 : 0
        await openDocument(docUrl, docSlug, docContent, '', { sale: saleParam, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onRefundSale(inscription) {
      this.refundingInscription = inscription
      if (inscription.sale) {
        this.confirmRefundText = 'Rembourser la vente ' + inscription.sale.label + '?'
        this.$bvModal.show('bv-confirm-modal:' + this.refundSaleModalName)
      }
    },
    async onConfirmRefundSale(event) {
      const sale = event.object.sale
      let backendApi = new BackendApi('post', '/api/refund-sales/')
      try {
        await backendApi.callApi({ sales: [sale.id], })
        await this.loadInscriptions()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    cancelExtraSale(sale) {
      if (!sale.ignored) {
        this.cancellingSale = sale
        this.cancelSaleModalText = 'Annuler ' + sale.label + '?'
        this.$bvModal.show('bv-confirm-modal:' + this.cancelSaleModalName)
      }
    },
    async onConfirmCancelExtraSale(event) {
      const sale = event.object
      let backendApi = new BackendApi('post', '/api/youth/cancel-extra-sale/')
      try {
        await backendApi.callApi({ sale: sale.id, })
        await this.addSuccess('La vente ' + sale.label + ' a été annulée.')
        await this.loadInscriptions()
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getDate(value) {
      return dateToString(value)
    },
    onSeancesChanged(event) {
      this.selectedSeances = event.choices.map(elt => elt.name)
    },
    onIndividualsChanged(event) {
      this.selectedIndividuals = event.choices
    },
    individualNameCallback(individual) {
      return individual.firstName
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .inline {
    display: inline-block;
    margin-left: 3px;
  }
  .table tr:first-of-type th, .table tr:first-of-type td {
    border-top: none
  }
  .table th, .table td {
    border-top-color: #aaa;
  }
  .group-header {
    background: #444;
    color: #fff;
    padding: 2px 5px;
    margin-top: 10px;
  }
  .table tr.tr-header th {
    color: #888;
    background: #ccc;
  }
  .checkbox-holder {
    position: relative;
    display: inline-block;
  }
  input[type="checkbox"] {
    position: absolute;
    opacity: 0.01;
    top: 4px;
    left: 2px;
  }
  input[type="checkbox"] + span:before {
    font: 14pt 'Font Awesome 6 Free';
    content: '\00f096';
    display: inline-block;
    width: 14pt;
    padding: 2px 0 0 3px;
    margin-right: 0.5em;
  }
  input[type="checkbox"]:checked + span:before {
    content: '\00f046';
  }
  input[type="checkbox"]:focus + span:before {
    outline: 1px dotted #aaa;
  }
  input[type="checkbox"]:disabled + span {
    color: #aaa;
    cursor: not-allowed;
  }
  .confirmation-warning {
    background: #f2f2a2;
    padding: 10px;
    font-size: 1.2em;
  }
  .seance-code {
    font-size: 0.8em;
  }
  .grid-col {
    text-align: center;
  }
  .seance-comments {
    font-size: 10px;
  }
  .ignored {
    text-decoration: line-through;
  }
  .extra-sale-line {
    margin-top: 5px;
    border-top: dashed 1px #888;
    padding-top: 5px;
  }
  .inscription-status {
    padding: 2px 5px;
    border: solid 1px #ccc;
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 2px;
    line-height: 24px;
    min-height: 30px;
    color: #000;
  }
  .inscription-status-cancelled {
    background: #ffca22;
  }
  .inscription-status-waiting {
    background-color: #8dfbff;
  }
  .inscription-status-refused {
    background-color: #f78888;
  }
  .inscription-status-not-confirmed {
    background-color: #7ec7f1;
  }
  .inscription-status-cancelled-not-confirmed {
    background: #ef8df5;
  }
  a.small-link {
    color: #222 !important;
    font-size: 12px;
  }
  .cancel-extra-button {
    display: none;
  }
  .extra-sale-line:hover .cancel-extra-button {
    display: inline-block;
    padding: 1px 5px;
    font-size: 12px;
    border: solid 1px #ccc;
  }
  .inscription-comments {
    font-size: 12px;
    color: #888;
  }
</style>

<template>
  <div class="multi-activities-inscriptions" v-if="canView">
    <page-header :title="title" icon="fa fa-th-list" :links="getLinks()">
    </page-header>
    <div ref="excelMe">
      <div>
        <b-row>
          <b-col cols="10" ref="printMe">
            <loading-gif :loading-name="loadingName"></loading-gif>
            <div v-if="!isLoading(loadingName)">
              <div v-if="selectedActivities.length" class="hide-here">
                <b v-html="activityNames"></b>
              </div>
              <x-table
                v-if="selectedActivities.length"
                :columns="columns"
                :items="items"
                show-counter
                verbose-name="inscrit"
                class="small"
              ></x-table>
            </div>
          </b-col>
          <b-col cols="2" class="small-text">
            <div v-if="schoolYears.length > 1" class="help-text">
              Ne saisir qu'une seule saison pour cette option
            </div>
            <b-form-checkbox
              v-model="onlyNoAdhesions"
              id="onlyNoAdhesions"
              class="small-checkbox"
              :disabled="schoolYears.length > 1"
            >
              Seulement les non-adhérents
            </b-form-checkbox>
            <activities-select
              :inline="false"
              :filter-block="true"
              :category="category"
              @changed="onActivitiesChanged"
            >
            </activities-select>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import ActivitiesSelect from '@/components/Activities/ActivitiesSelect.vue'
import { BackendMixin } from '@/mixins/backend'
import { ActivitiesMixin } from '@/mixins/activities'
import { makeIndividual, makeEntity } from '@/types/people'
import { BackendApi, openDocument } from '@/utils/http'
import XTable from '@/components/Controls/Table/Table.vue'
import { dateToString } from '@/filters/texts'
import { makeActivity } from '@/types/activities'
import { distinct } from '@/utils/arrays'

export default {
  name: 'multi-activities-inscriptions',
  props: {
    // youth: Boolean,
  },
  mixins: [BackendMixin, ActivitiesMixin],
  components: {
    XTable,
    ActivitiesSelect,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'multi-activities-inscriptions',
      items: [],
      selectedActivities: [],
      columns: [],
      onlyNoAdhesions: '',
    }
  },
  async created() {
    this.columns = this.getColumns()
  },
  watch: {
    onlyNoAdhesions() {
      this.loadInscriptions()
    },
  },
  computed: {
    schoolYears() {
      return distinct(this.selectedActivities.map(elt => elt.schoolYear))
    },
    canView() {
      return this.hasPerm('activities.view_coreactivityinscription')
    },
    title() {
      let text = 'Inscrits aux activités'
      const menu = this.getActivityMenu()
      if (menu && menu.category) {
        text += ' ' + menu.label
      }
      return text
    },
    activityNames() {
      return this.selectedActivities.map(
        elt => elt.name + ' ' + elt.schoolYear.name
      ).join(', ')
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onActivitiesChanged(event) {
      this.selectedActivities = event.activities
      this.loadInscriptions()
    },
    getField(item, field) {
      if (item.individual[field]) {
        return item.individual[field]
      }
      for (const contact of item.contacts) {
        if (contact[field]) {
          return contact[field]
        }
      }
      return ''
    },
    getEmail(item) {
      return this.getField(item, 'email')
    },
    getPhone(item) {
      let phone = this.getField(item, 'cellPhone')
      if (!phone) {
        phone = this.getField(item, 'proPhone')
      }
      return phone
    },
    async loadInscriptions() {
      this.startLoading(this.loadingName)
      let url = '/api/activities/multi-activities-inscriptions/'
      const backendApi = new BackendApi('post', url)
      const data = {
        activities: this.selectedActivities.map(elt => elt.id),
      }
      if (this.onlyNoAdhesions && this.schoolYears.length === 1) {
        data['only_no_adhesions'] = true
      }
      try {
        const resp = await backendApi.callApi(data)
        this.items = resp.data.map(
          elt => {
            return this.makeItem(
              {
                individual: makeIndividual(elt.individual),
                entities: elt.entities.map(elt2 => makeEntity(elt2)),
                contacts: elt.contacts.map(elt3 => makeIndividual(elt3)),
                activities: elt.activities.map(elt4 => makeActivity(elt4)),
              }
            )
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    getEntityLink(entity, tab) {
      const params = { entityId: '' + entity.id, }
      const query = {
        // individual: '' + inscription.individual.id,
        tab: tab,
      }
      return { name: 'families-detail', params: params, query: query, }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'inscriptions-aux-activites'
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      const isActive = !this.isLoading(this.loadingName) && (this.items.length > 0)
      let cssClass = 'btn-secondary'
      if (!isActive) {
        cssClass += ' disabled'
      }
      return [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: cssClass,
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: cssClass,
        }
      ]
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'inscriptions-aux-activites'
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getColumns() {
      return [
        {
          name: 'individualName',
          label: 'Nom',
          maxWidth: '200px',
          onClick: item => {
            this.showIndividualSidebar(item.individual)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'activitiesNames',
          label: 'Activités',
          maxWidth: '200px',
        },
        {
          name: 'birthDate',
          label: 'Naissance',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'citiesName',
          label: 'Ville',
          maxWidth: '100px',
        },
        {
          name: 'email',
          label: 'Email',
          displayAs: 'email',
          maxWidth: '150px',
        },
        {
          name: 'phone',
          label: 'Téléphone',
          displayAs: 'phone',
          maxWidth: '150px',
        }
      ]
    },
    makeItem(item) {
      let cities = item.entities.map(
        elt => elt.city.name
      ).join(', ')
      let activitiesNames = item.activities.map(
        elt => elt.name
      ).join(', ')
      return {
        id: item.individual.id,
        individual: item.individual,
        entities: item.entities,
        contacts: item.contacts,
        individualName: item.individual.lastAndFirstName(),
        birthDate: dateToString(item.individual.birthDate, 'YYYY-MM-DD'),
        citiesName: cities,
        phone: this.getPhone(item),
        email: this.getEmail(item),
        activitiesNames: activitiesNames,
      }
    },
  },
}
</script>

<style scoped lang="less">
</style>

<template>
  <div class="individual-sidebar sidebar" v-if="isAuthenticated">
    <b-sidebar
      id="sidebar-individual"
      right shadow width="35%" no-header
      @shown="onSideBarOpened()"
      @hidden="onSideBarClosed()"
      ref="individualSidebar"
    >
      <template #default="{ hide }">
        <div class="px-3 py-2" v-if="individual">
          <ul class="app-menu">
            <li>
              <b-row>
                <b-col><b><i class="fa fa-user"></i> {{ individual.firstAndLastName() }}</b></b-col>
                <b-col cols="1" class="text-right">
                  <i class="fa fa-close a-like clickable" @click="hide"></i>
                </b-col>
              </b-row>
            </li>
          </ul>
          <div v-if="nursery" class="sidebar-links">
            <a href @click.prevent="showNursery = false" :class="{ active: !showNursery }">Famille</a>
            <a href @click.prevent="showNursery = true" :class="{ active: showNursery }">Crèche</a>
          </div>
          <div v-if="!showNursery">
            <ul class="app-menu" v-for="membership of memberships" :key="membership.id">
              <li>
                <b-row>
                  <b-col>
                    <div>
                      <div>
                        <span v-if="membership.role.familyRole">Famille</span>
                        {{ membership.entity.name }}
                        <span class="badge badge-light">{{ membership.role.name }}</span>
                      </div>
                      <div></div>
                      <div v-if="membership.entity.phone">
                        <phone-link :phone="membership.entity.phone"></phone-link>
                      </div>
                      <div v-for="line of membership.entity.addressLines()" :key="line">
                        {{ line }}
                      </div>
                      <div>
                        {{ membership.entity.zipCode }}
                        <span v-if="membership.entity.city">{{ membership.entity.city.name }}</span>
                        {{ membership.entity.cedex }}
                      </div>
                    </div>
                  </b-col>
                  <b-col class="text-right" cols="3">
                    <a
                      v-if="hasPerm('families.view_family')"
                      :href="getLinkToEntity(membership)"
                      class="btn btn-primary btn-xs"
                      @click.prevent="viewEntity(membership)"
                    >
                      Voir la fiche
                    </a>
                  </b-col>
                </b-row>
              </li>
              <li v-if="member">
                <individual-identity
                  :is-family="entity.family"
                  :entity="entity"
                  :member="member"
                  :sidebar-mode="true"
                >
                </individual-identity>
              </li>
              <li v-if="isChild && member">
                <legal-representatives-detail
                  v-if="member.id && entity"
                  :member="member"
                  :entity="entity"
                  read-only
                >
                </legal-representatives-detail>
                <child-detail
                  :member="member"
                  read-only
                >
                </child-detail>
              </li>
              <li>
                <fields-detail
                  :show-individual="true"
                  :individual="individual"
                  :role="membership.role"
                  :entity="membership.entity"
                  :is-family="membership.role.familyRole"
                  read-only
                ></fields-detail>
              </li>
            </ul>
          </div>
          <div v-if="showNursery">
            <entity-nursery-inscriptions
              :nurseries="nurseries"
              :entity="entity"
              :individual="individual"
              :sidebar="true"
              id="sidebar"
            >
            </entity-nursery-inscriptions>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import LegalRepresentativesDetail from '@/components/Individual/LegalRepresentativesDetail'
import ChildDetail from '@/components/Individual/ChildDetail'
import FieldsDetail from '@/components/Fields/FieldsDetail'
import EntityNurseryInscriptions from '@/components/Entity/EntityNurseryInscriptions.vue'
import { makeIndividual, makeIndividualEntityMembership } from '@/types/people'
import router from '@/router'
import { makeFieldsGroup } from '@/types/fields'
import { mapMutations } from 'vuex'
import IndividualIdentity from '@/components/Individual/IndividualIdentity.vue'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import { makeNursery } from '@/types/nursery'

export default {
  name: 'individual-sidebar',
  components: {
    EntityNurseryInscriptions,
    PhoneLink,
    IndividualIdentity,
    LegalRepresentativesDetail,
    ChildDetail,
    FieldsDetail,
  },
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      memberships: [],
      init: false,
      showActivities: false,
      individual: null,
      isChild: false,
      member: null,
      nurseries: [],
      showNursery: false,
    }
  },
  computed: {
    isAuthenticated() {
      return store.getters.isAuthenticated
    },
    individualId() {
      if (store.getters.sidebarIndividual) {
        return store.getters.sidebarIndividual.id
      }
      return 0
    },
    nursery() {
      return store.getters.sidebarNursery
    },
    entity() {
      if (this.memberships.length) {
        return this.memberships[0].entity
      }
      return null
    },
  },
  watch: {
    individualId: async function() {
      await this.loadIndividual()
      await this.loadEntities()
      this.isChild = this.getIsChild()
      this.member = this.getMember()
    },
    showActivities: function() {
    },
    nursery: function() {
      this.showNursery = false
    },
  },
  methods: {
    ...mapMutations(['setFieldGroups']),
    async onSideBarOpened() {
      await this.loadFieldsGroups()
      if (this.nursery && this.nurseries.length === 0) {
        await this.loadNurseries()
      }
    },
    onSideBarClosed() {
      store.commit('setSidebarIndividual', null)
    },
    async loadFieldsGroups() {
      if (!this.init) {
        if (this.hasPerm('fields.view_fieldsgroup')) {
          const backendApi = new BackendApi('get', '/api/fields/groups/')
          const resp = await backendApi.callApi()
          this.setFieldGroups(resp.data.map(elt => makeFieldsGroup(elt)))
        }
        this.init = true
      }
    },
    async loadIndividual() {
      if (this.individualId) {
        const url = '/api/people/individual/' + this.individualId + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.individual = makeIndividual(resp.data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadEntities() {
      this.memberships = []
      if (this.individual && this.individual.id) {
        const url = '/api/people/individual-entities/' + this.individual.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.memberships = resp.data.map(makeIndividualEntityMembership)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    getEntityLink(membership) {
      const params = { entityId: '' + membership.entity.id, }
      const query = { individual: '' + this.individual.id, }
      if (membership.role.familyRole) {
        return { name: 'families-detail', params: params, query: query, }
      } else {
        return { name: 'entities-detail', params: params, query: query, }
      }
    },
    getLinkToEntity(membership) {
      const entity = membership.entity
      const isFamily = membership.role.familyRole
      let to
      if (isFamily) {
        to = { name: 'families-detail', params: { entityId: '' + entity.id, }, }
      } else {
        to = { name: 'entities-detail', params: { entityId: '' + entity.id, }, }
      }
      return router.resolve(to).href
    },
    viewEntity(membership) {
      router.push(this.getEntityLink(membership))
      store.commit('setSidebarIndividual', null)
    },
    getIsChild() {
      return this.memberships.filter(elt => elt.role.isChild).length > 0
    },
    getMember() {
      if (this.memberships.length) {
        const member = { ...this.memberships[0], }
        member.individual = this.individual
        return member
      }
      return null
    },
    async loadNurseries() {
      this.startLoading(this.loadingName)
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(makeNursery)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  font-size: 13px !important;
}
.individual-sidebar .app-menu li {
  font-size: 14px;
}
.sidebar-links {
  a {
    padding: 5px 10px;
    border: solid 1px #222;
    background: #fff;
    margin-right: 5px;
    text-decoration: none;
    font-size: 12px;
    color: #222 !important;
  }
  a.active {
    background: #222;
    color: #fff !important;
  }
}
</style>

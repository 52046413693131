import { Sale, makeSale } from '@/types/payments'
import { Individual, Entity, makeIndividual, makeEntity } from '@/types/people'
import { compareDays } from '@/utils/sorting'
import { ceilTime, diffTimes, displayHourMin, floorTime } from '@/utils/time'
import { getWeekDay } from '@/utils/dates'

export enum RevenueType {
  Yearly = 1,
  Monthly = 2
}

export enum OvertimePolicy {
  None = 0,
  Amplitude = 1, // dépassement comptabilisé sur l'amplitude horaire
  Clocking = 2 // dépassement comptabilisé par rapport à l'heure pointée
}

export enum NurseryContractType {
   Regular = 1,
   TimeToTime = 2,
   Changing = 3
}

export enum NurseryAbsence {
   None = 0,
   Refund = 1,
   Paid = 2
}

export enum NurseryAdaptation {
   None = 0,
   WithParents = 1,
   WithoutParents = 2
}

export enum NurseryDay {
  Monday = 0,
  Tuesday = 1,
  Wednesday = 2,
  Thursday = 3,
  Friday= 4,
  Saturday = 5,
  Sunday = 6
}

export function getDayName(day: number): string {
  const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
  if (day >= 0 && day < 7) {
    return days[day]
  }
  return '?'
}

export function getContractTypeLabel(contractType: NurseryContractType): string {
  switch (contractType) {
    case NurseryContractType.Regular:
      return 'Régulier'
    case NurseryContractType.TimeToTime:
      return 'Occasionnel'
    case NurseryContractType.Changing:
      return 'Atypique'
    default:
      return '?'
  }
}

export class AbsenceReason {
  constructor(
    public id: number,
    public name: string,
    public isProposed: boolean
  ) {
  }
}

export function makeAbsenceReason(jsonData: any = null): AbsenceReason {
  if (!jsonData) {
    jsonData = {}
  }
  return new AbsenceReason(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.is_proposed
  )
}

export class Nursery {
  constructor(
    public id: number,
    public name: string,
    public hasChild: boolean,
    public label: string,
    public address1: string,
    public address2: string,
    public reportNumber: string
  ) {
  }

  public getLabel(): string {
    return this.label || this.name
  }
}

export function makeNursery(jsonData: any = null): Nursery {
  if (!jsonData) {
    jsonData = {}
  }
  return new Nursery(
    jsonData.id || 0,
    jsonData.name || '',
    !!jsonData.has_child,
    jsonData.label || '',
    jsonData.address1 || '',
    jsonData.address2 || '',
    jsonData.report_number || ''
  )
}

export class NurseryFamilyRevenu {
  constructor(
    public id: number,
    public name: string,
    public revenue: number,
    public revenueType: RevenueType
  ) {
  }

  public clone(): NurseryFamilyRevenu {
    return new NurseryFamilyRevenu(
      this.id,
      this.name,
      this.revenue,
      this.revenueType
    )
  }
}

export class NurseryFamilyRateHistory {
  constructor(
    public id: number,
    public createdOn: Date,
    public year: number,
    public rate: number,
    public hourlyPrice: number|null
  ) {
  }
}

export class NurseryFamily {
  constructor(
    public id: number,
    public rawMonthlyRevenue: number,
    public monthlyRevenue: number,
    public rate: number,
    public childrenCount: number,
    public additionalSlice: number,
    public childPrice: number,
    public hourlyPrice: number,
    public lunchPrice: number,
    public additionalHourlyPrice: number,
    public additionalPrice: number,
    public emergency: boolean,
    public hasAdditionalPrice: boolean,
    public familyLevel: number,
    public revenues: NurseryFamilyRevenu[],
    public ratesHistory: NurseryFamilyRateHistory[]
  ) {
  }
}

export function makeNurseryFamilyRevenu(jsonData: any = null): NurseryFamilyRevenu {
  if (!jsonData) {
    jsonData = {}
  }
  return new NurseryFamilyRevenu(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.revenue || 0,
    jsonData.revenue_type || RevenueType.Yearly
  )
}

export function makeNurseryFamily(jsonData: any = null): NurseryFamily {
  if (!jsonData) {
    jsonData = {}
  }

  const revenuesData = jsonData.revenues || []
  const revenues: NurseryFamilyRevenu[] = revenuesData.map(
    (eltData: any) => makeNurseryFamilyRevenu(eltData)
  )

  const historyData = jsonData.rates_history || []
  const history: NurseryFamilyRateHistory[] = historyData.map(
    (elt: any) => {
      return new NurseryFamilyRateHistory(
        elt.id || 0,
        elt.created_on,
        elt.year || 0,
        elt.rate || 0,
        elt ? elt.hourly_price : null
      )
    }
  )

  return new NurseryFamily(
    jsonData.id || 0,
    jsonData.raw_monthly_revenue || 0,
    jsonData.monthly_revenue || 0,
    jsonData.rate || 0,
    jsonData.children_count || 0,
    jsonData.additional_slice || 0,
    jsonData.child_price || 0,
    jsonData.hourly_price || 0,
    jsonData.lunch_price || 0,
    jsonData.additional_hourly_price || 0,
    jsonData.additional_price || 0,
    !!jsonData.emergency,
    !!jsonData.has_additional_price,
    jsonData.family_level || 0,
    revenues,
    history
  )
}

export class NurseryContractItem {
  constructor(
    public id: number,
    public day: NurseryDay,
    public arrivalAt: string,
    public leavingAt: string,
    public lunch: boolean,
    public track: number,
    public weekIndex: number,
    public holidays: boolean
  ) {
  }

  public getDayName(): string {
    return getDayName(this.day)
  }

  public getWeekInfo(): string {
    if (this.holidays) {
      return 'Vacances ' + (this.weekIndex + 1)
    } else {
      return 'Semaine ' + (this.weekIndex + 1)
    }
  }
}

export class NurseryContractUpdate {
  constructor(
    public id: number,
    public createdOn: Date,
    public createdBy: string,
    public startDate: Date,
    public endDate: Date,
    public childPrice: number,
    public hourlyPrice: number,
    public lunchPrice: number
  ) {
  }
}

export class NurseryContract {
  constructor(
    public id: number,
    public nursery: Nursery,
    public createdOn: Date,
    public createdBy: string,
    public cancelledOn: Date | null,
    public cancelledBy: string,
    public modifiedOn: Date | null,
    public cancelInscriptionFrom: Date | null,
    public individual: Individual,
    public entity: Entity,
    public startDate: Date,
    public endDate: Date,
    public childPrice: number,
    public hourlyPrice: number,
    public lunchPrice: number,
    public hasAdditionalPrice: boolean,
    public additionalPrice: number,
    public additionalHourlyPrice: number,
    public weeksCycle: number,
    public holidaysCycle: number,
    public contractType: NurseryContractType,
    public items: NurseryContractItem[],
    public inscriptionsCount: number,
    public updates: NurseryContractUpdate[]
  ) {
  }

  public getContractTypeLabel(): string {
    return getContractTypeLabel(this.contractType)
  }

  public getCycle(): string {
    let text = ''
    if (this.weeksCycle > 1) {
      text = 'Cycle de ' + this.weeksCycle + ' semaines'
    } else {
      text = 'Toutes les semaines'
    }
    return text
  }

  public getHolidaysCycle(): string {
    let text = ''
    if (this.holidaysCycle) {
      text += 'sauf Vacances'
      if (this.holidaysCycle > 1) {
        text += ': cycle de ' + this.holidaysCycle + ' semaines'
      }
    }
    return text
  }

  public isActive(): boolean {
    const now = new Date()
    return (
      (compareDays(now, this.startDate) >= 0) &&
      (compareDays(this.endDate, now) >= 0)
    )
  }

  public isNext(): boolean {
    const now = new Date()
    return (
      (compareDays(this.endDate, now) > 0)
    )
  }

  public isFinished(): boolean {
    const now = new Date()
    return (
      (compareDays(this.endDate, now) < 0)
    )
  }

  public hasLunchPrice(): boolean {
    return (
      (this.lunchPrice > 0) &&
      (this.items.filter(item => item.lunch).length > 0)
    )
  }

  public hasHourlyPrice(): boolean {
    return (
      (this.hourlyPrice > 0) &&
      (this.individual.getAgeOn(this.endDate) < 4)
    )
  }

  public hasChildPrice(): boolean {
    return (
      (this.childPrice > 0) &&
      (this.individual.getAgeOn(this.endDate) >= 4)
    )
  }

  public getHourlyPrice(): number {
    return this.hourlyPrice + (this.hasAdditionalPrice ? this.additionalHourlyPrice : 0)
  }

  public getChildPrice(): number {
    return this.childPrice + (this.hasAdditionalPrice ? this.additionalPrice : 0)
  }
}

export function makeNurseryContractUpdate(jsonData: any = null): NurseryContractUpdate {
  if (!jsonData) {
    jsonData = {}
  }
  return new NurseryContractUpdate(
    jsonData.id || 0,
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.start_date,
    jsonData.end_date,
    jsonData.child_price || 0,
    jsonData.hourly_price || 0,
    jsonData.lunch_price || 0
  )
}

export function makeNurseryContract(jsonData: any = null): NurseryContract {
  if (!jsonData) {
    jsonData = {}
  }
  const jsonItems = jsonData.items || []
  const items: NurseryContractItem[] = []
  const jsonUpdates = jsonData.updates || []
  const updates: NurseryContractUpdate[] = jsonUpdates.map(makeNurseryContractUpdate)
  for (const jsonItem of jsonItems) {
    items.push(
      new NurseryContractItem(
        jsonItem.id,
        jsonItem.day,
        jsonItem.arrival_at || '',
        jsonItem.leaving_at || '',
        !!jsonItem.lunch,
        jsonItem.track || 0,
        jsonItem.week_index || 0,
        !!jsonItem.holidays
      )
    )
  }
  return new NurseryContract(
    jsonData.id || 0,
    makeNursery(jsonData.nursery),
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.cancelled_on,
    jsonData.cancelled_by || '',
    jsonData.modified_on,
    jsonData.cancel_inscription_from,
    makeIndividual(jsonData.individual),
    makeEntity(jsonData.entity),
    jsonData.start_date,
    jsonData.end_date,
    jsonData.child_price || 0,
    jsonData.hourly_price || 0,
    jsonData.lunch_price || 0,
    !!jsonData.has_additional_price,
    jsonData.additional_hourly_price || 0,
    jsonData.additional_price || 0,
    jsonData.weeks_cycle || 1,
    jsonData.holidays_cycle || 0,
    jsonData.contract_type,
    items,
    jsonData.inscriptions_count || 0,
    updates
  )
}

export class NurseryInscription {
  constructor(
    public id: number,
    public nursery: Nursery,
    public createdOn: Date,
    public createdBy: string,
    public individual: Individual,
    public entity: Entity,
    public date: Date,
    public hourlyPrice: number,
    public lunchPrice: number,
    public contract: NurseryContract|null,
    public arrivalAt: string,
    public leavingAt: string,
    public lunch: boolean,
    public track: number,
    public adaptation: NurseryAdaptation,
    public arrivedAt: string,
    public leftAt: string,
    public paidArrivedAt: string,
    public paidLeftAt: string,
    public overtimePolicy: OvertimePolicy,
    public extraDuration: number, // dépassement
    public absence: NurseryAbsence,
    public isSchool: boolean,
    public childPrice: number,
    public absenceReason: AbsenceReason|null,
    public arrivedWith: Individual|null,
    public leftWith: Individual|null,
    public cancelledOn: Date|null,
    public sale: Sale|null,
    public parents: Individual[]
  ) {
  }

  public getWeekDay(): NurseryDay {
    return getWeekDay(this.date)
  }

  public getAdaptationLabel(): string {
    if (this.adaptation === NurseryAdaptation.WithParents) {
      return 'Adaptation avec parents'
    }
    if (this.adaptation === NurseryAdaptation.WithoutParents) {
      return 'Adaptation sans parents'
    }
    return ''
  }

  public getAdaptationShortLabel(): string {
    if (this.adaptation === NurseryAdaptation.WithParents) {
      return 'Avec parents'
    }
    if (this.adaptation === NurseryAdaptation.WithoutParents) {
      return 'Sans parents'
    }
    return ''
  }

  public isAdaptationWithParents() {
    return this.adaptation === NurseryAdaptation.WithParents
  }

  public isAdaptationWithoutParents() {
    return this.adaptation === NurseryAdaptation.WithoutParents
  }

  public getArrival(): string {
    if (this.paidArrivedAt) {
      return this.paidArrivedAt
    }
    if (this.arrivedAt) {
      return this.arrivedAt
    }
    return this.arrivalAt
  }

  public getLeaving(): string {
    if (this.paidLeftAt) {
      return this.paidLeftAt
    }
    if (this.leftAt) {
      return this.leftAt
    }
    return this.leavingAt
  }

  public getArrival2(): string {
    return this.arrivedAt || this.arrivalAt
  }

  public getLeaving2(): string {
    return this.leftAt || this.leavingAt
  }

  public getAbsence(): string {
    if (this.absence) {
      if (this.absence && this.sale) {
        return 'Absence facturée'
      } else {
        return 'Absence'
      }
    }
    return ''
  }

  public getExpectedPrice(): number {
    if (this.isAdaptationWithParents()) {
      return 0
    }
    let price = 0
    if (this.isSchool) {
      price = +this.childPrice
    } else {
      let duration = this.getInscriptionDuration()
      price = duration * (+this.hourlyPrice)
    }
    if (this.lunch) {
      price += (+this.lunchPrice)
    }
    return price
  }

  public getListingDuration(): number|null {
    const arrival = this.arrivedAt || this.arrivalAt
    const leaving = this.leftAt || this.leavingAt
    if (leaving && arrival) {
      const duration = +diffTimes(leaving, arrival)
      return Math.round(duration / 36) / 100
    }
    return null
  }

  public getRealDuration(): number|null {
    if (this.leftAt && this.arrivedAt) {
      const duration = +diffTimes(this.leavingAt, this.arrivalAt)
      return Math.round(duration / 36) / 100
    }
    return null
  }

  public getReportDuration(): number|null {
    // Durée réalisée retenue par la CAF
    if (this.leftAt && this.arrivedAt) {
      const arrivedAt = floorTime(this.arrivedAt, 30)
      const leftAt = ceilTime(this.leftAt, 30)
      const duration = +diffTimes(leftAt, arrivedAt)
      return Math.round(duration / 36) / 100
    }
    return null
  }

  public getPaidDuration(): number|null {
    // Durée réalisée retenue par la CAF
    if (this.absence === NurseryAbsence.Refund) {
      return 0
    }
    if (this.isAdaptationWithParents()) {
      return 0
    }
    return this.getInscriptionDuration() + this.extraDuration
  }

  public getSafeReportDuration(): number {
    // La durée réalisée ne peut pas être supérieure à la durée facturée
    return Math.min(this.getReportDuration() || 0, this.getPaidDuration() || 0)
  }

  public getInscriptionDuration(): number {
    const duration = +diffTimes(this.leavingAt, this.arrivalAt)
    return Math.round(duration / 36) / 100
  }

  public isClockingDone(): boolean {
    return !!(this.arrivalAt && this.leftAt)
  }

  public isHereOnSlice(half: string): boolean {
    return (
      !this.absence &&
      (diffTimes(half, this.getArrival2()) >= 0) &&
      (diffTimes(this.getLeaving2(), half) > 0)
    )
  }
}

export function makeNurseryInscription(jsonData: any = null): NurseryInscription {
  if (!jsonData) {
    jsonData = {}
  }
  const parents = jsonData.parents || []
  return new NurseryInscription(
    jsonData.id || 0,
    makeNursery(jsonData.nursery),
    jsonData.created_on,
    jsonData.created_by || '',
    makeIndividual(jsonData.individual),
    makeEntity(jsonData.entity),
    jsonData.date,
    jsonData.hourly_price || 0,
    jsonData.lunch_price || 0,
    jsonData.contract ? makeNurseryContract(jsonData.contract) : null,
    jsonData.arrival_at || '',
    jsonData.leaving_at || '',
    !!jsonData.lunch,
    jsonData.track || 0,
    jsonData.adaptation || NurseryAdaptation.None,
    jsonData.arrived_at || '',
    jsonData.left_at || '',
    jsonData.paid_arrived_at || '',
    jsonData.paid_left_at || '',
    jsonData.overtile_policy || OvertimePolicy.None,
    jsonData.extra_duration || 0,
    jsonData.absence || NurseryAbsence.None,
    !!jsonData.is_school,
    jsonData.child_price || 0,
    jsonData.absence_reason ? makeAbsenceReason(jsonData.absence_reason) : null,
    jsonData.arrived_with ? makeIndividual(jsonData.arrived_with) : null,
    jsonData.left_with ? makeIndividual(jsonData.left_with) : null,
    jsonData.cancelled_on || null,
    jsonData.sale ? makeSale(jsonData.sale) : null,
    parents.map((elt: any) => makeIndividual(elt))
  )
}

export class NurseryLimit {
  constructor(
    public id: number,
    public day: NurseryDay,
    public startTime: string,
    public endTime: string,
    public limit: number
  ) {
  }
}

export function makeNurseryLimit(jsonData: any = null): NurseryLimit {
  if (!jsonData) {
    jsonData = {}
  }
  return new NurseryLimit(
    jsonData.id || 0,
    jsonData.day || 0,
    jsonData.start_time || '',
    jsonData.end_time || '',
    jsonData.limit || 0
  )
}

export class NurseryLimitEx {
  constructor(
    public id: number,
    public date: Date,
    public startTime: string,
    public endTime: string,
    public limit: number,
    public inscriptions: number,
    public isAvailable: boolean
  ) {
  }
}

export function makeNurseryLimitEx(jsonData: any = null): NurseryLimitEx {
  if (!jsonData) {
    jsonData = {}
  }
  return new NurseryLimitEx(
    jsonData.id || 0,
    jsonData.date,
    jsonData.start_time || '',
    jsonData.end_time || '',
    jsonData.limit || 0,
    jsonData.inscriptions || 0,
    !!jsonData['is_available']
  )
}

export class NurseryGridWeek {
  public constructor(
    public id: number,
    public index: number,
    public name: string,
    public holidays: boolean
  ) {
  }
}

export class NurseryGridDay {
  public constructor(
    public dayId: number,
    public week: NurseryGridWeek,
    public selected: boolean,
    public arrivalAt: string|null = null,
    public leavingAt: string|null = null,
    public arrivalAt2: string|null = null,
    public leavingAt2: string|null = null,
    public tracks: boolean = false
  ) {
  }

  public fullId(): string {
    return '' + this.week.id + '#' + this.dayId
  }

  public weekId(): number {
    return this.week.id
  }

  public weekIndex(): number {
    return this.week.index
  }

  public holidays(): boolean {
    return this.week.holidays
  }
}

export class NurseryOffDay {
  public constructor(
    public id: number,
    public date: Date,
    public startTime: string,
    public endTime: string,
    public comments: number,
    public presence: boolean,
    public holiday: boolean
  ) {
  }

  public getOffPeriod(): string {
    if (this.startTime && this.endTime) {
      return 'de ' + displayHourMin(this.startTime) + ' à ' + displayHourMin(this.endTime)
    } else {
      return 'pour la journée'
    }
  }

  public isPartial(): boolean {
    return !!(this.startTime && this.endTime)
  }
}

export function makeNurseryOffDay(jsonData: any = null): NurseryOffDay {
  if (!jsonData) {
    jsonData = {}
  }
  return new NurseryOffDay(
    jsonData.id || 0,
    jsonData.date,
    jsonData.start_time || '',
    jsonData.end_time || '',
    jsonData.comments || '',
    !!jsonData.presence,
    !!jsonData.holiday
  )
}

<template>
  <div v-if="entity && canView">
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div style="padding: 2px 10px">
      <b-row>
        <b-col cols="9"></b-col>
        <b-col class="text-right">
          <a
            class="btn-xs btn btn-secondary"
            href
            @click.prevent="newInscription()"
            v-if="canAdd"
          >
            <i class="fa fa-plus"></i> Ajouter une date
          </a>
        </b-col>
      </b-row>
    </div>
    <div v-if="!isLoading(loadingName) && summary.length === 0" class="empty-text field-line">
      Aucune inscription pour les années actives
    </div>
    <div v-if="summary.length" class="field-line small-text">
      <b-row>
        <b-col cols="2">
          <b-form-checkbox id="proposal" v-model="proposal">Devis</b-form-checkbox>
        </b-col>
        <b-col cols="8">
          <div v-if="proposal">
            <date-frame-selector
              id="proposal"
              :start-date="startProposalDate"
              :end-date="endProposalDate"
              @change="onProposalDateFrameChanged"
              small
              no-label
            >
            </date-frame-selector>
          </div>
        </b-col>
        <b-col class="text-right">
          <a
            v-if="proposal"
            class="btn btn-primary btn-sm"
            href @click.prevent="onProposal"
            :class="{ disabled: proposalDisabled, }"
          >
            <i class="fa fa-file-pdf"></i> PDF
          </a>
        </b-col>
      </b-row>
    </div>
    <div v-for="line of summary" :key="line.month" class="line">
      <div
        class="header-line sub-line clickable field-line"
        @click.prevent="showInscriptions(line)"
      >
        <b-row>
          <b-col cols="3"><span class="small-text">{{ line.nursery.getLabel() }}</span></b-col>
          <b-col cols="3"><b>{{ line.month | dateToString('MMMM YYYY') }}</b></b-col>
          <b-col cols="2">
            <span class="main-val">{{ roundedDuration(line.duration) }}</span>
            <span class="badge badge-light" v-if="line.extra">
              {{ relativeDuration(line.extra) }}
            </span>
            <span class="badge badge-danger" v-if="line.paidAbsence">{{ roundedDuration(line.paidAbsence) }}</span>
            <span class="badge badge-warning" v-if="line.refundAbsence">{{ roundedDuration(line.refundAbsence) }}</span>
          </b-col>
          <b-col cols="2">{{ line.price | currency }}</b-col>
          <b-col cols="2" class="text-right">
            <span :class="isVisible(line) ? 'btn-primary' : 'btn-secondary'" class="btn btn-sm">
              {{ line.count }}
            </span>
          </b-col>
        </b-row>
      </div>
      <div v-if="isVisible(line)">
        <loading-gif :loading-name="insLoadingName(line)"></loading-gif>
        <table class="table table-striped" :title="line.month | dateToString('MMMM YYYY')">
          <tr class="small-text">
            <th :colspan="sidebar ? 1 : 3">
              <div v-if="sidebar" class="small">
                <a class="badge" :class="sidebarPlanned ? 'badge-dark' : ''" href="" @click.prevent="sidebarPlanned = true">Prévu</a>
                <a class="badge" :class="sidebarPlanned ? '' : 'badge-dark'" href="" @click.prevent="sidebarPlanned = false">Réalisé</a>
              </div>
            </th>
            <th colspan="2" v-if="showPlanned">Prévu</th>
            <th></th>
            <th colspan="2" v-if="showReal">Réel</th>
            <th>Durée prévue</th>
            <th title="Dépassement" v-if="showReal">Dép.</th>
            <th class="text-right" v-if="showReal">Prix</th>
          </tr>
          <tr
            v-for="ins of getInscriptions(line)"
            :key="ins.id"
            class="field-line small-text clickable"
          >
            <td style="min-width: 160px;">
              {{ ins.date | dateToString('ddd D MMM YYYY') }}
              <div v-if="sidebar">
                <span :title="ins.getAdaptationLabel()" v-b-tooltip>
                <i class="fa fa-children" v-if="ins.isAdaptationWithParents()"></i>
                <i class="fa fa-baby" v-else-if="ins.isAdaptationWithoutParents()"></i>
                <i class="fa fa-transparent" v-else></i>
              </span>
              </div>
            </td>
            <td v-if="!sidebar">{{ ins.individual.lastAndFirstName() }}</td>
            <td style="width: 50px !important;"  v-if="!sidebar">
              <span :title="ins.getAdaptationLabel()" v-b-tooltip>
                <i class="fa fa-children" v-if="ins.isAdaptationWithParents()"></i>
                <i class="fa fa-baby" v-else-if="ins.isAdaptationWithoutParents()"></i>
                <i class="fa fa-transparent" v-else></i>
              </span>
            </td>
            <td style="width: 60px !important;" v-if="showPlanned">{{ displayHourMin(ins.arrivalAt) }}</td>
            <td style="width: 60px !important;" v-if="showPlanned">{{ displayHourMin(ins.leavingAt) }}</td>
            <td style="width: 20px;">
              <a href @click.prevent="selectInscription(line, ins)" class="btn btn-sm btn-secondary">
                <i class="fa fa-regular fa-frown-open" v-if="ins.absence === 1" title="absence remboursée" v-b-tooltip></i>
                <i class="fa fa-solid fa-frown" v-else-if="ins.absence === 2" title="absence facturée" v-b-tooltip></i>
                <i class="fa fa-solid fa-clock" title="pointage effectué" v-b-tooltip v-else-if="ins.isClockingDone()"></i>
                <i class="fa fa-regular fa-clock" title="pointage à faire" v-b-tooltip v-else></i>
              </a>
            </td>
            <td style="width: 120px !important;" v-if="ins.absence && showReal" colspan="2">
              <span class="badge badge-danger" v-if="ins.absence === 2">Absence facturée</span>
              <span class="badge badge-warning" v-else-if="ins.absence === 1">Absence remboursée</span>
            </td>
            <td style="width: 60px !important;" v-if="!ins.absence && showReal">{{ displayHourMin(ins.arrivedAt) }}</td>
            <td style="width: 60px !important;" v-if="!ins.absence && showReal">{{ displayHourMin(ins.leftAt) }}</td>
            <td style="width: 60px !important;">{{ ins.getInscriptionDuration() }}</td>
            <td style="width: 60px !important;" v-if="showReal"><span v-if="ins.extraDuration">{{ ins.extraDuration }}</span></td>
            <td style="width: 100px !important;" v-if="showReal" class="text-right number">
              <span v-if="ins.sale && !ins.sale.ignored">
                {{ ins.sale.price | currency }}
              </span>
            </td>
          </tr>
          <tr class="small">
            <td :colspan="sidebar ? 4: 8"></td>
            <th>{{ roundedDuration(sumDurations(line)) }}</th>
            <th v-if="showReal">{{ roundedDuration(sumExtraDurations(line)) }}</th>
            <th v-if="showReal" class="text-right number">{{ sumPrices(line) | currency }}</th>
          </tr>
        </table>
      </div>
    </div>
    <nursery-set-inscription-clocking-modal
      :id="'bv-ins-clocking-modal' + id"
      :inscription="selectedInscription"
      :allow-cancel="canDelete"
      @confirmed="patchInscription($event)"
      @split="splitedInscription"
    ></nursery-set-inscription-clocking-modal>
    <nursery-create-one-inscription-modal
      id="bv-create-one-nursery-inscription-modal2"
      :nurseries="nurseries"
      :entity="entity"
      date=""
      @confirmed="addInscription($event)"
    ></nursery-create-one-inscription-modal>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import NurseryCreateOneInscriptionModal from '@/components/Nursery/NurseryCreateOneInscriptionModal.vue'
import NurserySetInscriptionClockingModal from '@/components/Nursery/NurserySetInscriptionClockingModal.vue'
import { dateToString, duration } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeNursery, makeNurseryInscription } from '@/types/nursery'
import { firstDayOfMonth, lastDayOfMonth } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { displayHourMin } from '@/utils/time'

export default {
  name: 'entity-nursery-inscriptions',
  components: {
    DateFrameSelector,
    NurseryCreateOneInscriptionModal,
    NurserySetInscriptionClockingModal,
    LoadingGif,
  },
  mixins: [BackendMixin],
  props: {
    nurseries: {
      type: Array,
      default: function() { return [] },
    },
    entity: Object,
    individual: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: '',
    },
    sidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingName: 'entity-nursery-summary',
      summary: [],
      inscriptions: [],
      visibleMonths: new Map(),
      selectedInscription: null,
      selectedLine: null,
      proposal: false,
      startProposalDate: null,
      endProposalDate: null,
      sidebarPlanned: false,
    }
  },
  computed: {
    canView() {
      return this.hasPerm('nursery.view_nurseryinscription')
    },
    canAdd() {
      return this.hasPerm('nursery.add_nurseryinscription')
    },
    canDelete() {
      return this.hasPerm('nursery.delete_nurseryinscription')
    },
    proposalDisabled() {
      return !(this.startProposalDate && this.endProposalDate)
    },
    summaryStartDate() {
      if (this.summary.length) {
        return this.summary[0].month
      }
      return null
    },
    summaryEndDate() {
      if (this.summary.length) {
        const lastMonth = this.summary[this.summary.length - 1].month
        return lastDayOfMonth(lastMonth)
      }
      return null
    },
    showPlanned() {
      if (this.sidebar) {
        return this.sidebarPlanned
      }
      return true
    },
    showReal() {
      if (this.sidebar) {
        return !this.sidebarPlanned
      }
      return true
    },
  },
  watch: {
    entity: function() {
      this.inscriptions = []
      this.visibleMonths = new Map()
      this.onCreated()
    },
    individual: function() {
      this.visibleMonths = new Map()
      this.inscriptions = []
    },
    proposal: function() {
      this.startProposalDate = this.summaryStartDate
      this.endProposalDate = this.summaryEndDate
    },
  },
  created() {
    this.onCreated()
  },
  methods: {
    duration,
    displayHourMin,
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading', 'setEditMode']),
    async onCreated() {
      await this.loadInscriptionsSummary()
    },
    insLoadingName(line) {
      return 'entity-nursery-inscriptions' + line.month
    },
    async showInscriptions(line) {
      if (!this.isVisible(line)) {
        await this.loadInscriptions(line)
      } else {
        this.visibleMonths.delete(line.month)
      }
      this.visibleMonths = new Map(this.visibleMonths)
      this.$emit('changed', { months: this.visibleMonths.size, })
    },
    isVisible(line) {
      return this.visibleMonths.has(line.month)
    },
    getInscriptions(line) {
      return this.visibleMonths.get(line.month)
    },
    selectInscription(line, inscription) {
      this.selectedLine = line
      this.selectedInscription = inscription
      this.$bvModal.show('bv-ins-clocking-modal' + this.id)
    },
    patchInscription(event) {
      const inscriptions = this.getInscriptions(this.selectedLine)
      const index = inscriptions.map(elt => elt.id).indexOf(event.inscription.id)
      if (event.inscription.cancelledOn) {
        this.refreshInscription(event.inscription, true)
      } else {
        if (index >= 0) {
          inscriptions[index] = event.inscription
          this.visibleMonths = new Map(this.visibleMonths)
        }
      }
    },
    async loadInscriptionsSummary() {
      if (this.entity) {
        this.startLoading(this.loadingName)
        let url = '/nursery/api/nursery-inscriptions-summary/?entity=' + this.entity.id
        if (this.individual) {
          url += '&individual=' + this.individual.id
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.summary = resp.data.map(
            elt => {
              return {
                nursery: makeNursery(elt['nursery']),
                month: elt.month,
                duration: elt.duration,
                paid: elt.paid,
                count: elt.count,
                price: elt.price,
                extra: elt.paid ? (elt.paid - elt.due) : 0,
                paidAbsence: elt.paid_absence,
                refundAbsence: elt.refund_absence,
              }
            }
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadInscriptions(line) {
      if (this.entity) {
        const loadingName = this.insLoadingName(line)
        this.startLoading(loadingName)
        let startDate = dateToString(line.month, 'YYYY-MM-DD')
        let endDate = lastDayOfMonth(line.month)
        let url = '/nursery/api/nursery-inscriptions/?entity=' + this.entity.id
        url += '&nursery=' + line.nursery.id + '&start_date=' + startDate + '&end_date=' + endDate
        if (this.individual) {
          url += '&individual=' + this.individual.id
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.visibleMonths.set(line.month, resp.data.map(elt => makeNurseryInscription(elt)))
          this.visibleMonths = new Map(this.visibleMonths)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(loadingName)
      }
    },
    sumDurations(line) {
      return sum(this.getInscriptions(line).map(elt => elt.getInscriptionDuration()))
    },
    sumExtraDurations(line) {
      return sum(this.getInscriptions(line).map(elt => elt.extraDuration))
    },
    sumPrices(line) {
      return sum(this.getInscriptions(line).filter(elt => elt.sale && !elt.sale.ignored).map(elt => elt.sale.price))
    },
    newInscription() {
      this.$bvModal.show('bv-create-one-nursery-inscription-modal2')
    },
    async refreshInscription(inscription, forceAll = false) {
      const monthDate = firstDayOfMonth(inscription.date)
      // rechercher la ligne à partir de la date, l'ouvrir si ce n'est pas le cas et recharger
      let matchingLines = this.summary.filter(line => line.month === monthDate)
      if (forceAll || matchingLines.length === 0) {
        // si non trouvé, rafraîchir la synthèse et ouvrir la ligne correspondante
        await this.loadInscriptionsSummary()
      }
      matchingLines = this.summary.filter(line => line.month === monthDate)
      if (matchingLines.length) {
        await this.loadInscriptions(matchingLines[0])
      } else {
        // si toujours non trouvé -> on met un message d'erreur
        const strDate = dateToString(inscription.date, 'DD/MM/YYYY')
        this.addWarning('La date du ' + strDate + ' n\'est pas incluse dans la synthèse actuelle')
      }
    },
    addInscription(event) {
      const inscription = event.inscription
      this.refreshInscription(inscription)
    },
    splitedInscription(event) {
      const inscription = event.inscriptions[0]
      this.refreshInscription(inscription)
    },
    onProposalDateFrameChanged(event) {
      this.startProposalDate = event.startDate
      this.endProposalDate = event.endDate
    },
    async onProposal() {
      if (this.proposal && !this.proposalDisabled) {
        const docUrl = '/nursery/proposal/<key>/pdf/'
        const docSlug = '' + this.entity.id + 'X' + this.startProposalDate + 'X' + this.endProposalDate
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    roundedDuration(value) {
      return '' + (Math.round(value * 100) / 100) + 'h'
    },
    relativeDuration(value) {
      if (value > 0) {
        return '+' + this.roundedDuration(value)
      } else {
        return this.roundedDuration(value)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.main-val {
  display: inline-block;
  margin-right: 2px;
  vertical-align: top;
}
.line .badge {
  vertical-align: top;
  margin-right: 2px;
}
</style>

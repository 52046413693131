<template>
  <div class="activities-select">
    <b-row>
      <div v-if="hasInherited" style="margin-left: 15px;">
        <b-checkbox v-model="includeInherited">Inclure les ateliers</b-checkbox>
      </div>
      <b-col class="text-right" v-if="!filterBlock">
        <b-input v-model="activityFilter" class="small-input" placeholder="Filtrer les activités"></b-input>
        <a href @click.prevent="activityFilter=''" class="small-text" :class="{ disabled: activityFilter === '' }">
          <i class="fa fa-eraser"></i> RAZ
        </a>
      </b-col>
    </b-row>
    <b-row v-if="filterBlock">
      <b-col class="text-right">
        <b-input v-model="activityFilter" class="small-input" placeholder="Filtrer les activités"></b-input>
        <a href @click.prevent="activityFilter=''" class="small-text" :class="{ disabled: activityFilter === '' }">
          <i class="fa fa-eraser"></i> RAZ
        </a>
      </b-col>
    </b-row>
    <div v-if="visibleActivities.length === 0" class="empty">
      Aucune activité n'est ni sélectionnée ni ne correspond au filtre
    </div>
    <check-box-select
      id="activities"
      :inline="inline"
      :choices="visibleActivities"
      :name-callback="activityNameCallback"
      :style-callback="activityStyleCallback"
      @changed="activitiesChanged($event)"
    ></check-box-select>
  </div>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import CheckBoxSelect from '@/components/Controls/CheckBoxSelect'
import { BackendApi } from '@/utils/http'
import { makeActivity } from '@/types/activities'
import { compareNumbers } from '@/utils/sorting'
import { existsIn } from '@/utils/arrays'

export default {
  name: 'ActivitiesSelect',
  components: { CheckBoxSelect, },
  mixins: [BackendMixin],
  props: {
    title: {
      type: String,
      default: 'Sélectionner les activités',
    },
    inline: {
      type: Boolean,
      default: true,
    },
    filterBlock: {
      type: Boolean,
      default: false,
    },
    includeYouth: {
      type: Boolean,
      default: false,
    },
    category: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activities: [],
      selectedActivities: [],
      activityFilter: '',
      schoolYearIds: [],
      schoolYears: [],
      includeInherited: false,
    }
  },
  mounted() {
    this.selectedActivities = []
    this.loadActivities()
  },
  computed: {
    hasInherited() {
      return this.activities.filter(elt => elt.heirs.length > 0).length > 0
    },
    fullActivities() {
      let activities = []
      for (const activity of this.activities) {
        activities.push(activity)
        if (this.includeInherited && activity.heirs.length) {
          activities = activities.concat(activity.heirs)
        }
      }
      return activities
    },
    visibleActivities() {
      let activities = this.fullActivities
      const selectedIds = this.selectedActivities.map(elt => elt.id)
      if (this.activityFilter) {
        let filter = this.activityFilter.toLowerCase()
        activities = activities.filter(
          elt => (
            (elt.name.toLowerCase().indexOf(filter) >= 0) ||
            (existsIn([elt.id], selectedIds))
          )
        )
      }
      return activities
    },
  },
  watch: {
    activities: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    activitiesChanged(event) {
      this.selectedActivities = event.choices
      this.$emit('changed', { activities: this.selectedActivities, })
    },
    activityNameCallback(activity) {
      if (this.schoolYearIds.length > 1) {
        return activity.name + ' ' + activity.schoolYear.name
      } else {
        return activity.name
      }
    },
    activityStyleCallback(activity) {
      const colors = ['#ccc', '#f1b1d9', '#aaffaa', '#a3d7ff']
      if (this.schoolYearIds.length > 1) {
        const index = this.schoolYearIds.indexOf(activity.schoolYear.id)
        if (index >= 0) {
          return { background: colors[index % colors.length], }
        }
      }
      return { }
    },
    async loadActivities() {
      this.activities = []
      this.schoolYears = []
      this.schoolYearIds = []
      let url = '/api/activities/activities/?category=' + this.category
      if (this.includeYouth) {
        url += '&all=1'
      }
      let backendApi = new BackendApi('get', url)
      try {
        let resp = await backendApi.callApi()
        this.activities = resp.data.map(elt => makeActivity(elt))
        for (let activity of this.activities) {
          if (this.schoolYearIds.indexOf(activity.schoolYear.id) < 0) {
            this.schoolYearIds.push(activity.schoolYear.id)
            this.schoolYears.push(activity.schoolYear)
          }
        }
        this.schoolYears = this.schoolYears.sort(
          (year1, year2) => {
            return -compareNumbers(year1.startYear, year2.startYear)
          }
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <div id="app">
    <TopBar :version="version" />
    <WarningVersion v-bind:version="version" />
    <MessageBar />
    <IdleDetector />
    <IconsBar />
    <notes-button :updated="notesUpdated" @loaded="onNotesLoaded"></notes-button>
    <search-screen></search-screen>
    <add-family-screen></add-family-screen>
    <service-messages></service-messages>
    <up-button></up-button>
    <birthdays-button></birthdays-button>
    <online-help></online-help>
    <contact-support></contact-support>
    <notes-sidebar @update="onNotesUpdated"></notes-sidebar>
    <individual-sidebar></individual-sidebar>
    <entity-sidebar></entity-sidebar>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import 'font-awesome/css/font-awesome.css'
import { loadConfig } from '@/apis/config'
import { loadPermissions } from '@/apis/permissions'
import { loadFrontendInstance } from '@/apis/settings'
import TopBar from '@/components/Layout/TopBar.vue'
import WarningVersion from '@/components/Layout/WarningVersion'
import IconsBar from '@/components/Layout/IconsBar.vue'
import MessageBar from '@/components/Layout/MessageBar'
import OnlineHelp from '@/components/Layout/OnlineHelp'
import IdleDetector from '@/components/Controls/IdleDetector'
import ContactSupport from '@/components/Layout/ContactSupport'
import ServiceMessages from '@/components/Layout/ServiceMessages'
import NotesSidebar from '@/components/Notes/NotesSidebar'
import SearchScreen from '@/components/Search/SearchScreen.vue'
import EntitySidebar from '@/components/Entity/EntitySidebar'
import IndividualSidebar from '@/components/Individual/IndividualSidebar'
import AddFamilyScreen from '@/components/Family/AddFamilyScreen'
import UpButton from '@/components/Layout/UpButton'
import NotesButton from '@/components/Notes/NotesButton'
import BirthdaysButton from '@/components/Individual/BirthdaysButton'

export default {
  name: 'app',
  components: {
    NotesButton,
    UpButton,
    BirthdaysButton,
    AddFamilyScreen,
    IndividualSidebar,
    EntitySidebar,
    NotesSidebar,
    ContactSupport,
    OnlineHelp,
    WarningVersion,
    MessageBar,
    TopBar,
    IconsBar,
    IdleDetector,
    SearchScreen,
    ServiceMessages,
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      notesUpdated: false,
    }
  },
  mounted() {
    loadConfig()
    loadPermissions()
    loadFrontendInstance()
  },
  methods: {
    ...mapMutations(['setNotesUpdated']),
    onNotesUpdated() {
      this.notesUpdated = true
      this.setNotesUpdated(true)
      const that = this
      this.$nextTick(
        () => {
          that.setNotesUpdated(false)
        }
      )
    },
    onNotesLoaded() {
      this.notesUpdated = false
    },
  },
}
</script>

<style lang="less">
/* https://coolors.co/4d85af-f7f9fb-454851-231123-73956f */
html {
  overflow-x: hidden;
}
body {
  background: #fff !important;
  color: #222;
  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px !important;;
  position: relative;
}
.content {
  padding: 20px 35px 20px 50px;
}
@media(max-width: 949px) {
  .content {
    padding-top: 45px
  }
}
@media(max-width: 768px) {
  .top-bar-button-text {
    display: none;
  }
}
@media(max-width: 575px) {
  .content {
    padding-top: 70px
  }
}
#app {
  color: #333;
  margin-left: 40px;
  padding: 10px 10px 10px 10px;
}

.small-text {
  font-size: 14px;
}

.content h1 {
  font-size: 24px;
}

.content h2 {
  font-size: 22px;
}

.content h3 {
  font-size: 20px;
}

.millibase-logo i {
  color: #4d85af;
}

a {
  color: #285174 !important;
  text-decoration: underline;
}

a.disabled, a.disabled:hover, a.disabled:focus {
  color: #a0a0a0 !important;
}

.btn-primary {
  background-color: #4d85af !important;
  border-color: #4d85af !important;
  color: #fff !important;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #4d85af !important;
  border-color: #4d85af !important;
  color: #ececec !important;
}

.btn-primary:disabled, .btn-primary.disabled {
  background-color: #c0c0c0 !important;
  color: #f0f0f0 !important;
  border-color: #c0c0c0 !important;
}

.btn-secondary {
  background-color: #f7f9fb !important;
  color: #000 !important;
}

.btn-secondary:hover, .btn-secondary:focus {
  background-color: #f7f9fb !important;
  color: #444444 !important;
  text-decoration: none;
}

.btn-secondary.disabled {
  color: #c0c0c0 !important;
}

.btn-danger {
  background-color: #f78888 !important;
  border-color: #f78888 !important;
  color: #fff !important;
}

.btn-danger:hover, .btn-danger:focus {
  background-color: #f78888 !important;
  border-color: #f78888 !important;
  color: #ececec !important;
}

.btn-danger:disabled, .btn-danger.disabled {
  background-color: #c78888 !important;
  color: #f0f0f0 !important;
  border-color: #c78888 !important;
}

.btn-disabled, .btn-disabled:hover, .btn-disabled:focus {
  background-color: #c0c0c0 !important;
  color: #f0f0f0 !important;
  border-color: #c0c0c0 !important;
}

.modal-footer .btn-secondary{
  background-color: #f7f9fb;
  color: #000;
}

.modal-footer .btn-secondary:hover, .modal-footer .btn-secondary:focus {
  background-color: #f7f9fb;
  color: #000;
}

.sidebar {
  position: relative !important;
  z-index: 1050 !important;
}

a.btn, a.btn:hover {
  text-decoration: none;
}

.row-header,
.row-line {
  padding: 8px 5px;
}
.row-header, .row-footer {
  background: #454851;
  color: #fff;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.row-line, .list-line {
  background: #ececec;
}
.row-line:nth-of-type(odd), .list-line:nth-of-type(odd) {
  background: #e4e4e4;
}
.row-line.not-striped, .list-line.not-striped {
  background: #ececec;
}
.row-header, .row-line, .row-footer, .list-header, .list-line, .list-footer {
  border-bottom: solid 1px #ffffff;
}
.row-header:last-of-type, .list-header:last-of-type,
.row-line:last-of-type, .list-header:last-of-type,
.row-footer:last-of-type, .list-header:last-of-type {
  border-bottom: solid 1px transparent;
}
.row-header, .row-line, .row-footer {
  padding: 8px 5px;
}
.row-header, .row-footer, .list-header, .list-footer {
  background: #454851;
  color: #fff;
  font-weight: bold;
}

.content-headline h2 {
  display: inline-block;
}

.content-headline h2.small2 {
  font-size: 18px;
  font-weight: bold;
}

small, .small {
  font-size: 13px !important;
}

.small2 {
  font-size: 10px;
}

.grayed {
  color: #888;
}

.content-headline a.btn {
  display: inline-block;
  margin: 0 10px;
  vertical-align: top;
}

.content-headline a.btn:hover {
  text-decoration: none;
}

.content-headline .badges {
  margin-top: 0;
  margin-bottom: 0;
}

.content-headline .badges .badge {
  margin-left: 5px;
  color: #000;
  background: #ececec;
  margin-bottom: 5px;
}

.page-header-spacer {
  height: 22px;
  display: block;
  width: 100%;
}

.info-block {
  border: solid 1px #444;
  border-radius: 8px;
  padding: 10px;
  margin: 20px 0;
}

.first-block {
  border: solid 1px #444;
  margin-bottom: 20px;
  border-radius: 4px;
}

.first-block.no-border {
  border: none;
  margin-top: 3px;
  margin-bottom: 20px;
}

.first-block-form {
  padding: 0 10px;
}

.buttons-bar {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-top: solid 1px #ccc;
  padding: 5px 0;
}

.buttons-bar .btn{
  margin: 0 5px;
}

a.btn-sm-right {
  float: right;
  padding: 2px 5px;
  font-size: 12px;
}
.new-title {
  font-style: italic;
  color: #808182;
}
.form-group label {
  font-weight: bold;
}
.header-bar {
  background: #ccc;
  padding: 5px;
  border-radius: 4px 4px 0 0;
}
.header-bar h2 {
  padding: 0;
  margin: 0;
}

.right-menu {
  text-align: right;
  margin-bottom: 20px;
}

.right-menu a{
  display: block;
  margin-bottom: 10px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
}

.field-group-block {
  padding: 0 0 10px 0;
  border: solid 1px #444;
  border-radius: 4px;
  margin: 20px 0;
}

.field-group-block-inner {
  padding: 10px;
}

.field-group-header {
  background: #ccc;
  padding: 5px 10px;
  border-radius: 4px 4px 0 0;
  min-height: 42px;
}

.field-line, .header-line {
  margin-bottom: 2px;
  padding: 3px 10px;
  border-top: solid 1px #ccc;
}

.header-line {
  background: #4d85af;
  color: #fff;
}

.header-line.inactive {
  background: #888;
  color: #aaa;
}

.header-line.sub-line {
  background: #c0c0c0;
  color: #000;
}

.field-line.field-line-no-border {
  border-top: none;
}

.field-line-group {
  margin-bottom: 20px;
}

.recent-items {
  margin-bottom: 20px;
  text-align: right;
  background: #ececec;
  padding: 10px;
  border-radius: 4px;
}

.recent-items h3 {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: solid #fff 3px;
}

.recent-line {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #fff;
}

.recent-line:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

ul.app-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.app-menu li {
  padding: 10px;
  margin: 5px 0;
  font-size: 20px;
  display: block;
  border-bottom: solid 1px #c8cfc8;
}

ul.app-menu li a{
  cursor: pointer;
  text-decoration: none;
  color: #4b526d;
}

ul.app-menu li a.underline{
  text-decoration: underline;
}

ul.app-menu li a:hover {
  text-decoration: underline;
}

ul.app-menu li a.btn{
  padding: 6px;
  font-size: 14px;
  text-decoration: none;
}

.list-group-item {
  padding: 5px 10px !important;
  font-size: 14px;
}

.list-group-item-action.active {
  background: #ececec !important;
  border: none;
}

.input-group-primary .input-group-text {
  background: #ececec;
  border-color: #ececec;
  color: #454851;
}

.badge-light {
  background-color: #f7f9fb !important;
  color: #888 !important;
  border: solid 1px #888;
  display: inline-block;
}

.badge-max {
  white-space: normal !important;
  max-width: 100%;
}

.badge-dark {
  background-color: #444 !important;
  color: #fff !important;
  display: inline-block;
}

.badge-error {
  background-color: #f78888 !important;
  color: #000 !important;
  border: solid 1px #888;
  display: inline-block;
}

.badge-warning {
  color: #222 !important;
  border: solid 1px #222;
}

.badge-normal-wrap {
  white-space: normal !important;
}

.badge-small-text {
  font-size: 14px !important;
}

.badge-success {
  background-color: #73956f !important;
  color: #fff !important;
  border: solid 1px #222;
  display: inline-block;
}

.small-badge {
  font-size: 8px;
  padding: 2px;
  border-radius: 50%;
  display: inline-block;
  min-width: 15px;
  text-align: center;
  vertical-align: middle;
}

.badge-inline {
  display: inline-block !important;
  margin-right: 1px;
}

.tiny-badge {
  font-size: 9px;
  padding: 1px 5px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.tiny-badge-bold {
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

.with-separators {
  margin: 10px 0;
  padding: 10px 0;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}
.field-error {
  font-size: 14px;
  background: #f77438;
  padding: 2px;
  color: #fff;
}
.form-control-error {
  border: solid 1px #f77438 !important;
}
.small-row .row {
  padding: 0;
  margin: 0;
}
.small-row .col {
  display: flex;
  width: 100%;
  flex-basis: unset;
  padding: 2px
}
.small-row .col:nth-of-type(odd) {
  background: #f0f0f0;
}
.btn-xs {
  font-size: 12px !important;
  padding: 2px !important;
}
.main-line {
  background: #ccc;
  padding: 4px;
}
input[type="checkbox"][disabled="disabled"],
input[type="checkbox"][disabled="disabled"]+label {
  cursor: not-allowed;
}
.empty-text {
  color: #aaa;
}
.empty-value {
  font-style: italic;
}
table.table-striped tr:nth-of-type(odd) td{
  background: #f0f0f0;
}
table.table-hover tr:hover td{
  background: #f3ffd5;
}
.sub-header {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #e0e0e0;
}
.arrived-at {
  background: #98cbd3;
  color: #000;
}
.left-at {
  background: #3b4d6a;
  color: #fff;
}
.arrived-at2 {
  background: #e5a8bd;
  color: #000;
}
.left-at2 {
  background: #8d4f5c;
  color: #fff;
}
.arrived-at3 {
  background: #b2d294;
  color: #000;
}
.left-at3 {
  background: #668a41;
  color: #fff;
}
.tooltip.b-tooltip-info .tooltip-inner{
   background-color: #4d85af !important;
}
.tooltip.b-tooltip-info .arrow:before{
   border-right-color: #4d85af !important;
}
.col-border {
  border-right: solid 1px #d0d0d0;
}
.comments {
  color: #444;
  font-style: italic;
  font-size: 14px;
}
.warning-line {
  color: #832929;
  padding: 5px;
  margin: 5px 0;
  font-weight: bold;
  background: #e0e0e0;
}
.valid-line {
  color: #000;
  padding: 5px;
  margin: 5px 0;
  font-weight: bold;
  background: #e0e0e0;
}
.app-logo img.normal {
  width: 200px;
}
.no-warning-text {
  padding: 20px;
  background: #c0c0c0;
}

.warning-text {
  padding: 10px;
  font-weight: bold;
  background: #fdacac;
}

.info-text {
  padding: 10px;
  font-weight: bold;
  background: #fdffd1;
}

.error-text {
  padding: 20px;
  background: #c84444;
  color: #fff;
}
.clickable {
  cursor: pointer;
}
.text-italic {
  font-style: italic;
}
.family-numbers{
  display: inline-block;
  background: #285174 !important;
  color: #fff;
  border-radius: 100%;
  padding: 8px 4px 0 4px;
  font-size: 11px;
  height: 32px;
  min-width: 32px;
  vertical-align: bottom;
  text-align: center !important;
  margin-top: -3px;
  margin-right: 2px;
}
.small-family-numbers {
  padding: 4px 2px 0 2px;
  font-size: 9px;
  height: 24px;
  min-width: 24px;
}
.small-family-numbers2 {
  padding: 4px 4px 2px 4px;
  font-size: 9px;
  height: 24px;
  min-width: 24px;
  background: #285174 !important;
  color: #fff;
  border-radius: 50%;
}
.text-right {
  text-align: right;
}
.no-padding {
  padding: 0 !important;
}
.dropdown-item-small .dropdown-item{
  padding: 0.25rem 0.5rem;
  font-size: 14px;
}
.cancelled-invoice {
  background: #aeaeae;
}
.frozen-invoice {
  background: #fe5757;
}
.verified-invoice {
  background: #89f685;
}
.paid-invoice {
  background: #c9f684;
}
.sent-invoice {
  background: #f6a70a;
}
.send-invoice {
  background: #f6c85e;
}
.credit-invoice {
  background: #f65eee;
}
.new-invoice {
  background: #59b6fa;
}
.todo-invoice {
  background: #fc7ab6;
}
.limit-indicator-info {
  font-size: 13px;
}
.hide-here {
  display: none;
}
.badge-block {
  display: inline-block;
  max-width: 100%;
  white-space: normal !important;
}
.right-bar-icon {
  font-size: 18px;
  background: #454851;
  position: fixed;
  right: 5px;
  color: #fff;
  padding: 10px 8px;
  border-radius: 50%;
  text-align: center;
  min-width: 38px;
  height: 38px;
  display: inline-block;
}
.right-bar-icon.grayed {
  background: #aaa;
  color: #eee;
}
.right-bar-icon.highlighted {
  background: #b83375;
  color: #fff;
}

.icon-button {
  font-size: 18px;
  background: #4d85af;
  color: #fff;
  padding: 8px;
  border-radius: 50%;
  display: inline-block;
}
.icon-button-big {
  font-size: 26px;
  background: #454851;
  color: #fff;
  padding: 12px;
  border-radius: 50%;
  display: inline-block;
  min-width: 54px;
  text-align: center;
}
a.show-about {
  font-size: 12px;
  color: #444 !important;
  text-decoration: underline;
}
a.btn-disabled {
  color: #ccc !important;
  border: solid 1px #ccc;
  display: inline-block;
}
::selection {
  background-color: #adc4d5;
}
select.small-select {
  font-size: 12px;
  padding: 2px 5px;
}
select.highlighted {
  background: #ffca22;
}
input.highlighted {
  background: #ffca22;
}
input.small-input {
  font-size: 12px;
  padding: 2px 5px;
}

.small-check label {
  font-size: 14px;
  padding-top: 2px;
  vertical-align: bottom;
}

a.top-bar-button {
  border: solid 1px #fff;
  color: #fff !important;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-block;
  text-decoration: none !important;
}
a.top-bar-button:hover {
  border: solid 1px #e5e5e5;
  color: #e5e5e5 !important;
}
.btns a {
  margin-right: 3px;
}
.small-input-text .form-control {
  font-size: 12px !important;
  padding: 3px;
}
.field-description {
  color: #444;
  font-size: 11px;
}
.custom-control.custom-checkbox.inline {
  display: inline-block;
  margin-right: 15px;
}
.custom-control.custom-checkbox.inline:last-of-type {
  margin-right: 0;
}
input.filled,
input.filled:focus {
  background: #ffe9b6;
}
.article-title {
  font-weight: bold;
  padding: 4px;
  border-radius: 2px;
  background: #add1d1;
}
.article-box ul {
  background: #c4eeee;
  padding: 5px;
  margin: 0;
  list-style: none;
}
.no-wrap {
  white-space: nowrap;
}
.help-text {
  font-size: 10px;
  color: #888;
}
.help-text2 {
  font-size: 12px;
  color: #888;
}
.help-text3 {
  font-size: 14px;
  color: #444;
}
a.light-link {
  color: #fff !important;
}
a.fake-link {
  cursor: pointer;
}
.small-text-col {
  font-size: 12px;
}
table.small-table {
  font-size: 10px;
}
table.small-table td, table.small-table th{
  padding: 2px;
}
table.small-table td, table.small-table th{
  padding: 1px;
  font-size: 9px;
}
table.small-table th{
  border: none;
}
.table.medium-table {
  font-size: 13px;
}
.table.medium-table > tbody > tr > th {
  padding: 6px;
  font-size: 13px;
}

.table.medium-table > tbody > tr > td {
  padding: 4px;
  font-size: 13px;
}
.modal-full {
  max-width: 90% !important;
}
</style>
